import { Route } from 'react-router-dom';
import { getRelayEnvironment } from '@/RelayEnvironment';
import { loadProjectImprovementsPageQuery } from './ProjectImprovementsPageQuery.ts';
import { loadAreaOfImprovementPageQuery } from './AreaOfImprovementPageQuery.ts';
import { ProjectImprovementsSubPageLayout } from './ProjectImprovementsSubPageLayout.tsx';
import type { AreaOfImprovementPageProps } from './AreaOfImprovementPage.tsx';

export default (
    <>
        <Route
            path={`get-started`}
            lazy={() =>
                import('./GetStartedWithProjectImprovements.tsx').then((m) => ({
                    Component: m.GetStartedWithProjectImprovements,
                }))
            }
        />
        <Route
            path={`legacy/*`}
            lazy={() =>
                import('./legacy/LegacyProjectImprovementsPage.tsx').then(
                    (m) => ({
                        Component: m.LegacyProjectImprovementsPage,
                    }),
                )
            }
        />

        <Route
            id="ProjectImprovements"
            loader={({ params }) => {
                return {
                    queryRef: loadProjectImprovementsPageQuery(
                        getRelayEnvironment(),
                        {
                            teamPath: params.teamPath,
                            projectPath: params.projectPath,
                        },
                    ),
                };
            }}
        >
            <Route
                index
                lazy={() =>
                    import('./ProjectImprovementsPage.tsx').then((m) => ({
                        Component: m.ProjectImprovementsPageLoader,
                    }))
                }
            />
            <Route Component={ProjectImprovementsSubPageLayout}>
                <Route
                    path="add-area-of-improvement"
                    lazy={() =>
                        import('./AddAreaOfImprovementPage.tsx').then((m) => ({
                            Component: m.AddAreaOfImprovementPageLoader,
                        }))
                    }
                />
            </Route>
        </Route>
        <Route Component={ProjectImprovementsSubPageLayout}>
            <Route
                path="area-of-improvement/:slug"
                lazy={() =>
                    import('./AreaOfImprovementPage.tsx').then((m) => ({
                        Component: m.AreaOfImprovementPageLoader,
                    }))
                }
                id="AreaOfImprovementPage"
                loader={({ params }): AreaOfImprovementPageProps => {
                    return {
                        navigateToSlug(navigate, slug) {
                            navigate(`../area-of-improvement/${slug}`, {
                                replace: true,
                            });
                        },
                        queryRef: loadAreaOfImprovementPageQuery(
                            getRelayEnvironment(),
                            {
                                teamPath: params.teamPath,
                                projectPath: params.projectPath,
                                slug: params.slug,
                            },
                        ),
                    };
                }}
            >
                <Route
                    path="add-improvement-activity"
                    lazy={() =>
                        import(
                            './AreaOfImprovementPageAddImprovementActivityDialog.tsx'
                        ).then((m) => ({
                            Component:
                                m.AreaOfImprovementPageAddImprovementActivityDialog,
                        }))
                    }
                />
                <Route
                    path="edit/:activitySlug"
                    lazy={() =>
                        import(
                            './AreaOfImprovementPageEditImprovementActivityDialog.tsx'
                        ).then((m) => ({
                            Component:
                                m.AreaOfImprovementPageEditImprovementActivityDialog,
                        }))
                    }
                />
            </Route>
        </Route>
    </>
);
