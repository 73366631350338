/**
 * This module uses dynamic import with template string limiting the locales to: "en|sv". To add an app locale,
 * you should replace all occurrences of en|sv with a new expression including the added locale.
 */
import { shouldPolyfill as shouldPolyfillIntlGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlDisplayNames } from '@formatjs/intl-displaynames/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlListFormat } from '@formatjs/intl-listformat/should-polyfill';
import type { AppLocale } from '../i18n';

// Not using LocaleMatcher (to my knowing)
interface MaybePolyfilled {
    polyfilled?: boolean;
}

export default async function intl(locale: AppLocale) {
    const dataPolyfills = [] as Promise<unknown>[];

    if (shouldPolyfillIntlGetCanonicalLocales()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }
    if (shouldPolyfillIntlLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfillIntlNumberFormat()) {
        await import('@formatjs/intl-numberformat/polyfill');
    }
    if ((Intl.NumberFormat as MaybePolyfilled).polyfilled) {
        dataPolyfills.push(
            import(
                /* webpackInclude: /\/(en|sv)\.js$/ */
                `./@formatjs/intl-numberformat/locale-data/${locale}.ts`
            ),
        );
    }
    if (shouldPolyfillDateTimeFormat()) {
        await import('@formatjs/intl-datetimeformat/polyfill');
    }
    if ((Intl.DateTimeFormat as MaybePolyfilled).polyfilled) {
        await Promise.all([
            import('@formatjs/intl-datetimeformat/add-all-tz'),
            import(
                /* webpackInclude: /\/(en|sv)\.js$/ */
                `./@formatjs/intl-datetimeformat/locale-data/${locale}.ts`
            ),
        ]);
    }

    if (shouldPolyfillIntlPluralRules()) {
        await import('@formatjs/intl-pluralrules/polyfill');
    }
    if ((Intl.PluralRules as MaybePolyfilled).polyfilled) {
        dataPolyfills.push(
            import(
                /* webpackInclude: /\/(en|sv)\.js$/ */
                `./@formatjs/intl-pluralrules/locale-data/${locale}.ts`
            ),
        );
    }
    if (shouldPolyfillIntlRelativeTimeFormat()) {
        await import('@formatjs/intl-relativetimeformat/polyfill');
    }
    if ((Intl.RelativeTimeFormat as MaybePolyfilled).polyfilled) {
        dataPolyfills.push(
            import(
                /* webpackInclude: /\/(en|sv)\.js$/ */
                `./@formatjs/intl-relativetimeformat/locale-data/${locale}.ts`
            ),
        );
    }

    if (shouldPolyfillIntlDisplayNames()) {
        await import('@formatjs/intl-displaynames/polyfill');
    }
    if ((Intl.DisplayNames as MaybePolyfilled).polyfilled) {
        dataPolyfills.push(
            import(
                /* webpackInclude: /\/(en|sv)\.js$/ */
                `./@formatjs/intl-displaynames/locale-data/${locale}.ts`
            ),
        );
    }

    if (shouldPolyfillIntlListFormat()) {
        dataPolyfills.push(import('@formatjs/intl-listformat/polyfill'));
        dataPolyfills.push(
            import(
                /* webpackInclude: /\/(en|sv)\.js$/ */
                `./@formatjs/intl-listformat/locale-data/${locale}.ts`
            ),
        );
    }

    await Promise.all(dataPolyfills);
}
