import { useFragment, graphql } from 'react-relay';
import Text from './Text';
import type { PreambleRecord_content$key } from './__generated__/PreambleRecord_content.graphql';

export default function PreambleRecord(props: {
    content: PreambleRecord_content$key;
}) {
    const content = useFragment(contentFragment, props.content);
    return <Text>{content.text}</Text>;
}

const contentFragment = graphql`
    fragment PreambleRecord_content on PreambleRecord {
        id
        text
    }
`;
