import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { SymbolIcon } from '../icons';
import Logo from '../Logo';
import type { LogoProps } from '../Logo';

export type TopBarWrapperProps = {
    variant: 'logo' | 'symbol' | 'none';
    logoContainerWidth: number;
    symbolContainerWidth: number;
    symbolSize?: number;
    logoSize?: LogoProps['size'];
    children?: ReactNode;
};

/**
 * Wraps the topbar and displays a logo/symbol to the left if the collapsible sidebar is disabled
 * @param props
 * @constructor
 */
export const TopBarWithLogo = (props: TopBarWrapperProps) => {
    const {
        children,
        variant,
        logoContainerWidth,
        symbolContainerWidth,
        symbolSize = 32,
        logoSize = 'md',
    } = props;
    if (variant === 'none') {
        return children;
    }
    return (
        <Stack direction="row">
            <Box
                component={Link}
                to={'/'}
                sx={(theme) => ({
                    color: theme.bino.color.white,
                    backgroundColor: theme.bino.color.primary,
                    justifyContent: 'center',
                    width: `${
                        variant === 'logo'
                            ? logoContainerWidth
                            : symbolContainerWidth
                    }px`,
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                {variant === 'symbol' ? (
                    <SymbolIcon size={symbolSize} />
                ) : (
                    <Logo white size={logoSize} />
                )}
            </Box>
            <Box flexGrow={1}>{children}</Box>
        </Stack>
    );
};
