import { graphql, loadQuery } from 'react-relay';
import type { Environment } from 'react-relay';
import type {
    AreaOfImprovementPageQuery,
    AreaOfImprovementPageQuery$variables,
} from './__generated__/AreaOfImprovementPageQuery.graphql';

export const loadAreaOfImprovementPageQuery = (
    environment: Environment,
    variables: AreaOfImprovementPageQuery$variables,
) => {
    return loadQuery<AreaOfImprovementPageQuery>(
        environment,
        areaOfImprovementPageQuery,
        variables,
    );
};

export const areaOfImprovementPageQuery = graphql`
    query AreaOfImprovementPageQuery(
        $projectPath: String!
        $teamPath: String!
        $slug: String!
    ) {
        team: projectMeasuring_teamByPath(path: $teamPath) {
            ...AddImprovementActivityPage_team
            ...EditImprovementActivityPage_team
            project: projectByPath(projectPath: $projectPath) {
                ...AddImprovementActivityPage_project
                ...EditImprovementActivityPage_project
                improvementBank {
                    areaOfImprovement(slug: $slug) {
                        ...AddImprovementActivityPage_areaOfImprovement
                        ...EditImprovementActivityPage_areaOfImprovement
                        id
                        summary
                        problemDescription
                        tags {
                            __typename
                            ... on AreaOfImprovementIndicatorTag {
                                questionnaireId
                                indicatorKey
                            }
                            ... on AreaOfImprovementQuestionTag {
                                questionId
                            }
                        }
                        activities {
                            ...ImprovementActivityCard_activity
                            ...EditImprovementActivityPage_activity
                            slug
                        }
                    }
                }
            }
            masterQuestionnaire {
                ...useAreaOfImprovementTagSelectOptions_questionnaire
                ...AreaOfImprovementTagSelect_questionnaire
            }
        }
    }
`;
