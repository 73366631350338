import { useFragment, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import type { ArticleLinkRecord_content$key } from './__generated__/ArticleLinkRecord_content.graphql';

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    color: theme.bino.color.primary,
    margin: 'calc(15px / 2) 0 0 0',
    // It looks a bit too intrusive with the uppercase and bold, even if we bring the font-size down.
    // Trying this for a while. /Marcus
    // textTransform: 'uppercase',
    // fontWeight: 'bold',

    [theme.breakpoints.up('sm')]: {
        margin: 'calc(25px / 2) 0 0 0',
    },
}));
export default function ArticleLinkRecord(props: {
    content: ArticleLinkRecord_content$key;
}) {
    const { articleLink, linkText } = useFragment(
        contentFragment,
        props.content,
    );
    const { topic, url: articleUrl } = articleLink;
    return (
        <StyledLink to={`../${topic.url}#${articleUrl}`}>{linkText}</StyledLink>
    );
}

const contentFragment = graphql`
    fragment ArticleLinkRecord_content on ArticleLinkRecord {
        id
        linkText
        articleLink {
            topic {
                url
            }
            url
        }
    }
`;
