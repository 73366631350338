import { styled } from '@mui/material';
import { useFragment, graphql } from 'react-relay';
import type { HeadingRecord_content$key } from './__generated__/HeadingRecord_content.graphql';

export default function HeadingRecord(props: {
    content: HeadingRecord_content$key;
}) {
    const content = useFragment(contentFragment, props.content);
    return <Heading>{content.headerText}</Heading>;
}

const contentFragment = graphql`
    fragment HeadingRecord_content on HeadingRecord {
        id
        headerText
    }
`;

const Heading = styled('h3')(({ theme }) => ({
    fontSize: 16,
    lineHeight: '26px',
    color: theme.palette.text.primary,
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: '24px 0',

    [theme.breakpoints.up('sm')]: {
        margin: '35px 0',
    },
}));
