import { graphql } from 'react-relay';
export type * from './__generated__/TeamPageLayoutRouteQuery.graphql.ts';

export const teamPageLayoutRouteQuery = graphql`
    query TeamPageLayoutRouteQuery($teamPath: String!) {
        team: projectMeasuring_teamByPath(path: $teamPath) {
            ...TopBar_team
            ...MainMenu_team
            ...useOnlyMyProjects_team
        }
        pqiUser {
            ...MainMenu_pqiUser
            ...TopBar_pqiUser
            ...useOnlyMyProjects_pqiUser
        }
    }
`;
