/**
 * @generated SignedSource<<5a5b0ece36a0f348295d14add92346ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteLocale = "en_GB" | "sv_SE" | "%future added value";
export type HelpCenterRelatedArticlesSidebarQuery$variables = {
  locale: SiteLocale;
  pageId: string;
};
export type HelpCenterRelatedArticlesSidebarQuery$data = {
  readonly list: {
    readonly relatedArticles: ReadonlyArray<{
      readonly id: string;
      readonly summary: string | null | undefined;
      readonly title: string | null | undefined;
      readonly topic: {
        readonly url: string | null | undefined;
      } | null | undefined;
      readonly url: string | null | undefined;
    }>;
  } | null | undefined;
};
export type HelpCenterRelatedArticlesSidebarQuery = {
  response: HelpCenterRelatedArticlesSidebarQuery$data;
  variables: HelpCenterRelatedArticlesSidebarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = [
  {
    "alias": "list",
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "pageId"
              }
            ],
            "kind": "ObjectValue",
            "name": "pageId"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      },
      {
        "kind": "Variable",
        "name": "locale",
        "variableName": "locale"
      }
    ],
    "concreteType": "HelpCenterRelatedArticlesListRecord",
    "kind": "LinkedField",
    "name": "helpCenterRelatedArticlesList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HelpCenterArticleRecord",
        "kind": "LinkedField",
        "name": "relatedArticles",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "summary",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "HelpCenterTopicRecord",
            "kind": "LinkedField",
            "name": "topic",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HelpCenterRelatedArticlesSidebarQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HelpCenterRelatedArticlesSidebarQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "739497d34a3ad8a9e8ba0ed5dba9d4e2",
    "id": null,
    "metadata": {},
    "name": "HelpCenterRelatedArticlesSidebarQuery",
    "operationKind": "query",
    "text": "query HelpCenterRelatedArticlesSidebarQuery(\n  $locale: SiteLocale!\n  $pageId: String!\n) {\n  list: helpCenterRelatedArticlesList(locale: $locale, filter: {pageId: {eq: $pageId}}) {\n    relatedArticles {\n      id\n      title\n      summary\n      url\n      topic {\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0858363eba4bb959f16e56b4bfca5ab3";

export default node;
