// @ts-strict-ignore
import { Component } from 'react';
import type { MouseEvent, ReactNode } from 'react';
import { Snackbar, snackbarClasses } from '@mui/material';
import type { SnackbarCloseReason } from '@mui/material/Snackbar/Snackbar';
import { SnackbarContext } from '../SnackbarContext';
import Content from './Content';
import type { MessageSeverity } from './Content';

interface SnackbarProviderProps {
    children?: ReactNode;
}

export interface SnackbarOptions {
    /**
     * The default severity is "info"
     */
    severity?: MessageSeverity;

    /**
     * The default autoHideDuration is 10 seconds (10000)
     * Setting this to null will disable auto hide
     */
    autoHideDuration?: number | null;
}

interface SnackbarProviderState {
    open: boolean;
    messageInfo: {
        message: string;
        options: SnackbarOptions;
        key: string;
    };
}

const emptyMessageInfo = {
    message: '',
    key: '',
    options: {},
};

export class SnackbarProvider extends Component<
    SnackbarProviderProps,
    SnackbarProviderState
> {
    state = {
        open: false,
        messageInfo: emptyMessageInfo,
    } as SnackbarProviderState;

    queue = [];

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({
                messageInfo: this.queue.shift(),
                open: true,
            });
        } else {
            this.setState({
                messageInfo: emptyMessageInfo,
                open: false,
            });
        }
    };

    handleClose = (event: MouseEvent, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };

    handleExited = () => {
        this.processQueue();
    };

    /**
     * Adds a new snackbar to the queue to be presented.
     */
    handleEnqueueSnackbar = (
        message: ReactNode,
        options: SnackbarOptions = {},
    ) => {
        this.queue.push({
            message,
            key: new Date().getTime(),
            options,
        });

        if (this.state.open) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({ open: false });
        } else {
            this.processQueue();
        }
    };

    render() {
        const { children, ...props } = this.props;
        const {
            open,
            messageInfo: { key, message, options },
        } = this.state;

        return (
            <SnackbarContext.Provider
                value={{
                    enqueueSnackbar: this.handleEnqueueSnackbar,
                }}
            >
                <>
                    {children}
                    <Snackbar
                        autoHideDuration={
                            options?.autoHideDuration === undefined
                                ? 10000
                                : options.autoHideDuration
                        }
                        {...props}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={(theme) => ({
                            top: 0,
                            [`&.${snackbarClasses.anchorOriginTopLeft}`]: {
                                left: 0,
                                right: 0,
                                top: 0,
                                [theme.breakpoints.up('sm')]: {
                                    left: 16,
                                    right: 16,
                                    top: 16,
                                },
                                [theme.breakpoints.up('md')]: {
                                    left: 24,
                                    right: 24,
                                    top: 24,
                                },
                            },
                        })}
                        open={open}
                        key={key}
                        TransitionProps={{
                            onExited: this.handleExited,
                        }}
                        onClose={this.handleClose}
                    >
                        {message ? (
                            <Content
                                severity={options.severity}
                                message={message}
                                handleClose={this.handleClose}
                            />
                        ) : null}
                    </Snackbar>
                </>
            </SnackbarContext.Provider>
        );
    }
}
