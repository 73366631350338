/**
 * @generated SignedSource<<4a5753f78396715b0edaf80e61ae9b21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicTableOfContents_articles$data = ReadonlyArray<{
  readonly id: string;
  readonly title: string | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentType": "TopicTableOfContents_articles";
}>;
export type TopicTableOfContents_articles$key = ReadonlyArray<{
  readonly " $data"?: TopicTableOfContents_articles$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicTableOfContents_articles">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TopicTableOfContents_articles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "HelpCenterArticleRecord",
  "abstractKey": null
};

(node as any).hash = "c0138b1f24c9de092413c04e58e5e5c0";

export default node;
