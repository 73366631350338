import type { CacheConfig, OperationType } from 'relay-runtime';
import { QueryRenderer } from 'react-relay';
import type { QueryRendererProps, FetchPolicy } from 'react-relay';
import type { ReactNode } from 'react';
import { Grid, Typography, Skeleton, styled } from '@mui/material';
import { Paper } from '@/components';
import { useIsScreenSmallerThanBreakpoint } from '@/muiTheme';
import { HelpCenterRelayEnvironment } from '../HelpCenterRelayEnvironment';

type HelpCenterPageLayoutProps<TOperation extends OperationType> = {
    cacheConfig?: CacheConfig | null | undefined;
    fetchPolicy?: FetchPolicy | undefined;
    renderContent: (props: TOperation['response']) => ReactNode;
    renderBreadcrumb: (props: TOperation['response']) => ReactNode;
} & Omit<QueryRendererProps<TOperation>, 'render' | 'environment'>;

export default function HelpCenterPageLayout<TOperation extends OperationType>({
    renderContent,
    renderBreadcrumb,
    fetchPolicy = 'store-or-network',
    ...props
}: HelpCenterPageLayoutProps<TOperation>) {
    return (
        <QueryRenderer<TOperation>
            {...props}
            fetchPolicy={fetchPolicy}
            environment={HelpCenterRelayEnvironment}
            render={({ props, error }) => {
                if (error) {
                    throw error;
                }

                if (!props) {
                    return <HelpCenterStartPageSkeleton />;
                }
                return (
                    <div>
                        {renderBreadcrumb(props)}
                        {renderContent(props)}
                    </div>
                );
            }}
            {...props}
        />
    );
}

function HelpCenterStartPageSkeleton() {
    const isMobile = useIsScreenSmallerThanBreakpoint('xs');
    const gridItemHeight = isMobile ? 250 : 350;
    return (
        <div>
            <Nav>
                <Typography component="div" variant="subtitle2">
                    <Skeleton width={100} />
                </Typography>
            </Nav>
            <Grid
                container
                component={Paper}
                responsive
                style={{
                    display: 'block',
                }}
                spacing={3}
            >
                <Grid item>
                    <Skeleton width={200} height={55} variant="rectangular" />
                </Grid>
                <Grid item>
                    <Skeleton
                        width={isMobile ? 300 : 400}
                        height={25}
                        variant="rectangular"
                    />
                </Grid>
                <Grid item>
                    <Skeleton
                        height={130}
                        variant="rectangular"
                        style={{
                            display: 'block',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: 'grid',
                        gridTemplateColumns:
                            'repeat(auto-fit, minmax(250px, 1fr))',
                        gridGap: 16,
                    }}
                >
                    {Array.from({ length: isMobile ? 2 : 3 }).map((_, idx) => (
                        <Skeleton
                            key={idx}
                            height={gridItemHeight}
                            component="div"
                            variant="rectangular"
                        />
                    ))}
                </Grid>
            </Grid>
        </div>
    );
}

const Nav = styled('nav')(({ theme }) => ({
    margin: 16,
    [theme.breakpoints.up('sm')]: {
        margin: '8px 0 24px 0',
    },
}));
