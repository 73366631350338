import { StrictMode, useEffect, useReducer, useState } from 'react';
import {
    CssBaseline,
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { IntlProvider } from 'react-intl';
import { RelayEnvironmentProvider } from 'react-relay';
import { LocalizationProvider as DatePickersLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from '@/features/app-notifications';
import {
    useRelayEnvironmentAndReset,
    addResetRelayEnviromentListener,
} from '@/RelayEnvironment';
import { addChangeLocaleListener, getLocale, getLocaleData } from './i18n';
import { initialiseRecoilAtoms, RecoilObserver } from './global-states';
import { createMuiTheme } from './muiTheme';
import { addDarkModeChangedListener, useDarkMode } from './utils/useDarkMode';
import { AppSuspense } from './AppSuspense';
import routes from './App.routes.tsx';

const router = createBrowserRouter(createRoutesFromElements(routes), {
    // async unstable_patchRoutesOnMiss({ matches, patch }) {
    //     const leafRoute = matches[matches.length - 1]?.route;
    //     console.log({ matches, leafRoute });
    //     if (leafRoute?.handle?.lazyChildren) {
    //         const children = await leafRoute.handle.lazyChildren();
    //         console.log({ children });
    //         patch(leafRoute.id, children);
    //     }
    // },
});

function App() {
    const [darkMode] = useDarkMode();
    const [{ locale, localeData }, setLocaleAndLocaleData] = useState({
        locale: getLocale(),
        localeData: getLocaleData(),
    });
    const [relayEnvironment] = useRelayEnvironmentAndReset();

    const [x, forceUpdate] = useReducer((x) => x + 1, 0);
    useEffect(() => {
        addChangeLocaleListener((locale, localeData) => {
            console.log('Change locale to ' + locale);
            setLocaleAndLocaleData({ locale, localeData });
        });
        addDarkModeChangedListener(forceUpdate);

        addResetRelayEnviromentListener(forceUpdate);
    }, []);

    return (
        <div lang={locale} translate="yes" key={x}>
            <StrictMode>
                <IntlProvider
                    locale={locale}
                    key={locale}
                    messages={localeData.messages}
                    defaultRichTextElements={{
                        u: (chunks) => <u>{chunks}</u>,
                        b: (chunks) => <b>{chunks}</b>,
                    }}
                >
                    <RecoilRoot initializeState={initialiseRecoilAtoms}>
                        <RecoilObserver />
                        <StyledEngineProvider>
                            <MuiThemeProvider
                                theme={createMuiTheme(
                                    localeData.muiTheme,
                                    darkMode ? 'dark' : 'light',
                                )}
                            >
                                <CssBaseline />
                                <DatePickersLocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    localeText={localeData.muiXDatePickers}
                                    adapterLocale={localeData.dateFns}
                                >
                                    <RelayEnvironmentProvider
                                        environment={relayEnvironment}
                                    >
                                        <SnackbarProvider>
                                            <AppSuspense>
                                                <RouterProvider
                                                    router={router}
                                                />
                                            </AppSuspense>
                                        </SnackbarProvider>
                                    </RelayEnvironmentProvider>
                                </DatePickersLocalizationProvider>
                            </MuiThemeProvider>
                        </StyledEngineProvider>
                    </RecoilRoot>
                </IntlProvider>
            </StrictMode>
        </div>
    );
}

export default App;
