export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** Represents `true` or `false` values. */
    BooleanType: any;
    CustomData: any;
    /** A ISO 8601 compliant datetime value */
    DateTime: any;
    /**
     * Represents signed double-precision fractional values as specified by [IEEE
     * 754](http://en.wikipedia.org/wiki/IEEE_floating_point).
     */
    FloatType: any;
    /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
    IntType: any;
    ItemId: any;
    JsonField: any;
    MetaTagAttributes: any;
    UploadId: any;
};

/** Record of type Article link (article_link) */
export type ArticleLinkRecord = {
    readonly __typename?: 'ArticleLinkRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly articleLink?: Maybe<HelpCenterArticleRecord>;
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly linkText?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Article link (article_link) */
export type ArticleLinkRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
    /** Search for records with an exact match */
    readonly eq?: Maybe<Scalars['BooleanType']>;
};

export type CollectionMetadata = {
    readonly __typename?: 'CollectionMetadata';
    readonly count: Scalars['IntType'];
};

export enum ColorBucketType {
    red = 'red',
    orange = 'orange',
    pink = 'pink',
    cyan = 'cyan',
    purple = 'purple',
    blue = 'blue',
    yellow = 'yellow',
    green = 'green',
    brown = 'brown',
    grey = 'grey',
    white = 'white',
    black = 'black',
}

export type ColorField = {
    readonly __typename?: 'ColorField';
    readonly alpha?: Maybe<Scalars['IntType']>;
    readonly blue?: Maybe<Scalars['IntType']>;
    readonly green?: Maybe<Scalars['IntType']>;
    readonly hex?: Maybe<Scalars['String']>;
    readonly red?: Maybe<Scalars['IntType']>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
    /** Filter records with a value that's strictly greater than the one specified */
    readonly gt?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's less than the one specified */
    readonly lt?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's greater than or equal to than the one specified */
    readonly gte?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's less or equal than the one specified */
    readonly lte?: Maybe<Scalars['DateTime']>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
    /** Search for records with an exact match (seconds are truncated) */
    readonly eq?: Maybe<Scalars['DateTime']>;
    /** Exclude records with an exact match */
    readonly neq?: Maybe<Scalars['DateTime']>;
};

export enum FaviconType {
    icon = 'icon',
    appleTouchIcon = 'appleTouchIcon',
    msApplication = 'msApplication',
}

export type FileField = {
    readonly __typename?: 'FileField';
    readonly _createdAt: Scalars['DateTime'];
    readonly _updatedAt: Scalars['DateTime'];
    readonly alt?: Maybe<Scalars['String']>;
    readonly author?: Maybe<Scalars['String']>;
    readonly basename: Scalars['String'];
    readonly blurUpThumb?: Maybe<Scalars['String']>;
    readonly blurhash?: Maybe<Scalars['String']>;
    readonly colors: ReadonlyArray<Maybe<ColorField>>;
    readonly copyright?: Maybe<Scalars['String']>;
    readonly customData?: Maybe<Scalars['CustomData']>;
    readonly exifInfo?: Maybe<Scalars['CustomData']>;
    readonly filename: Scalars['String'];
    readonly focalPoint?: Maybe<FocalPoint>;
    readonly format: Scalars['String'];
    readonly height?: Maybe<Scalars['IntType']>;
    readonly id: Scalars['UploadId'];
    readonly mimeType: Scalars['String'];
    readonly notes?: Maybe<Scalars['String']>;
    readonly responsiveImage?: Maybe<ResponsiveImage>;
    readonly size: Scalars['IntType'];
    readonly smartTags: ReadonlyArray<Maybe<Scalars['String']>>;
    readonly tags: ReadonlyArray<Maybe<Scalars['String']>>;
    readonly title?: Maybe<Scalars['String']>;
    readonly url: Scalars['String'];
    readonly video?: Maybe<UploadVideoField>;
    readonly width?: Maybe<Scalars['IntType']>;
};

export type FileFieldAltArgs = {
    locale?: Maybe<SiteLocale>;
};

export type FileFieldBlurUpThumbArgs = {
    punch?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Int']>;
    quality?: Maybe<Scalars['Int']>;
    imgixParams?: Maybe<ImgixParams>;
};

export type FileFieldCustomDataArgs = {
    locale?: Maybe<SiteLocale>;
};

export type FileFieldFocalPointArgs = {
    locale?: Maybe<SiteLocale>;
};

export type FileFieldResponsiveImageArgs = {
    imgixParams?: Maybe<ImgixParams>;
    sizes?: Maybe<Scalars['String']>;
};

export type FileFieldTitleArgs = {
    locale?: Maybe<SiteLocale>;
};

export type FileFieldUrlArgs = {
    imgixParams?: Maybe<ImgixParams>;
};

export type FocalPoint = {
    readonly __typename?: 'focalPoint';
    readonly x?: Maybe<Scalars['FloatType']>;
    readonly y?: Maybe<Scalars['FloatType']>;
};

export type GlobalSeoField = {
    readonly __typename?: 'GlobalSeoField';
    readonly facebookPageUrl?: Maybe<Scalars['String']>;
    readonly fallbackSeo?: Maybe<SeoField>;
    readonly siteName?: Maybe<Scalars['String']>;
    readonly titleSuffix?: Maybe<Scalars['String']>;
    readonly twitterAccount?: Maybe<Scalars['String']>;
};

/** Record of type Heading (heading) */
export type HeadingRecord = {
    readonly __typename?: 'HeadingRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly headerText?: Maybe<Scalars['String']>;
    readonly id: Scalars['ItemId'];
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Heading (heading) */
export type HeadingRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

export type HelpCenterArticleModelArticleContentBlocksField =
    | ArticleLinkRecord
    | HeadingRecord
    | ImageRecord
    | PreambleRecord
    | TextRecord
    | VideoRecord;

export type HelpCenterArticleModelArticleContentField = {
    readonly __typename?: 'HelpCenterArticleModelArticleContentField';
    readonly blocks: ReadonlyArray<HelpCenterArticleModelArticleContentBlocksField>;
    readonly links: ReadonlyArray<HelpCenterArticleRecord>;
    readonly value: Scalars['JsonField'];
};

export type HelpCenterArticleModelArticleContentFieldMultiLocaleField = {
    readonly __typename?: 'HelpCenterArticleModelArticleContentFieldMultiLocaleField';
    readonly locale?: Maybe<SiteLocale>;
    readonly value?: Maybe<HelpCenterArticleModelArticleContentField>;
};

export type HelpCenterArticleModelContentField =
    | ArticleLinkRecord
    | HeadingRecord
    | ImageRecord
    | PreambleRecord
    | TextRecord
    | VideoRecord;

export type HelpCenterArticleModelContentFieldListMultiLocaleField = {
    readonly __typename?: 'HelpCenterArticleModelContentFieldListMultiLocaleField';
    readonly locale?: Maybe<SiteLocale>;
    readonly value?: Maybe<
        ReadonlyArray<Maybe<HelpCenterArticleModelContentField>>
    >;
};

export type HelpCenterArticleModelFilter = {
    readonly _createdAt?: Maybe<CreatedAtFilter>;
    readonly createdAt?: Maybe<CreatedAtFilter>;
    readonly id?: Maybe<ItemIdFilter>;
    readonly _firstPublishedAt?: Maybe<PublishedAtFilter>;
    readonly position?: Maybe<PositionFilter>;
    readonly _publicationScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _publishedAt?: Maybe<PublishedAtFilter>;
    readonly _status?: Maybe<StatusFilter>;
    readonly _updatedAt?: Maybe<UpdatedAtFilter>;
    readonly updatedAt?: Maybe<UpdatedAtFilter>;
    readonly _isValid?: Maybe<BooleanFilter>;
    readonly articleContent?: Maybe<StructuredTextFilter>;
    readonly url?: Maybe<SlugFilter>;
    readonly topic?: Maybe<LinkFilter>;
    readonly summary?: Maybe<TextFilter>;
    readonly title?: Maybe<StringFilter>;
    readonly OR?: Maybe<ReadonlyArray<Maybe<HelpCenterArticleModelFilter>>>;
};

export enum HelpCenterArticleModelOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
    _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
    position_ASC = 'position_ASC',
    position_DESC = 'position_DESC',
    _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
    _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
    _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
    _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
    _publishedAt_ASC = '_publishedAt_ASC',
    _publishedAt_DESC = '_publishedAt_DESC',
    _status_ASC = '_status_ASC',
    _status_DESC = '_status_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    _isValid_ASC = '_isValid_ASC',
    _isValid_DESC = '_isValid_DESC',
    title_ASC = 'title_ASC',
    title_DESC = 'title_DESC',
}

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecord = {
    readonly __typename?: 'HelpCenterArticleRecord';
    readonly _allArticleContentLocales?: Maybe<
        ReadonlyArray<
            Maybe<HelpCenterArticleModelArticleContentFieldMultiLocaleField>
        >
    >;
    readonly _allContentLocales?: Maybe<
        ReadonlyArray<
            Maybe<HelpCenterArticleModelContentFieldListMultiLocaleField>
        >
    >;
    readonly _allSummaryLocales?: Maybe<
        ReadonlyArray<Maybe<StringMultiLocaleField>>
    >;
    readonly _allTitleLocales?: Maybe<
        ReadonlyArray<Maybe<StringMultiLocaleField>>
    >;
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly articleContent?: Maybe<HelpCenterArticleModelArticleContentField>;
    readonly content?: Maybe<
        ReadonlyArray<Maybe<HelpCenterArticleModelContentField>>
    >;
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly position?: Maybe<Scalars['IntType']>;
    readonly summary?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
    readonly topic?: Maybe<HelpCenterTopicRecord>;
    readonly updatedAt: Scalars['DateTime'];
    readonly url?: Maybe<Scalars['String']>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecord_AllArticleContentLocalesArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecord_AllContentLocalesArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecord_AllSummaryLocalesArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecord_AllTitleLocalesArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecordArticleContentArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecordContentArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecordSummaryArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Article (help_center_article) */
export type HelpCenterArticleRecordTitleArgs = {
    locale?: Maybe<SiteLocale>;
};

export type HelpCenterRelatedArticlePageModelFilter = {
    readonly _createdAt?: Maybe<CreatedAtFilter>;
    readonly createdAt?: Maybe<CreatedAtFilter>;
    readonly id?: Maybe<ItemIdFilter>;
    readonly _firstPublishedAt?: Maybe<PublishedAtFilter>;
    readonly _publicationScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _publishedAt?: Maybe<PublishedAtFilter>;
    readonly _status?: Maybe<StatusFilter>;
    readonly _updatedAt?: Maybe<UpdatedAtFilter>;
    readonly updatedAt?: Maybe<UpdatedAtFilter>;
    readonly _isValid?: Maybe<BooleanFilter>;
    readonly url?: Maybe<StringFilter>;
    readonly pageName?: Maybe<StringFilter>;
    readonly OR?: Maybe<
        ReadonlyArray<Maybe<HelpCenterRelatedArticlePageModelFilter>>
    >;
};

export enum HelpCenterRelatedArticlePageModelOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
    _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
    _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
    _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
    _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
    _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
    _publishedAt_ASC = '_publishedAt_ASC',
    _publishedAt_DESC = '_publishedAt_DESC',
    _status_ASC = '_status_ASC',
    _status_DESC = '_status_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    _isValid_ASC = '_isValid_ASC',
    _isValid_DESC = '_isValid_DESC',
    url_ASC = 'url_ASC',
    url_DESC = 'url_DESC',
    pageName_ASC = 'pageName_ASC',
    pageName_DESC = 'pageName_DESC',
}

/** Record of type Help center - Related Article - Page (help_center_related_article_page) */
export type HelpCenterRelatedArticlePageRecord = {
    readonly __typename?: 'HelpCenterRelatedArticlePageRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly pageName?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
    readonly url?: Maybe<Scalars['String']>;
};

/** Record of type Help center - Related Article - Page (help_center_related_article_page) */
export type HelpCenterRelatedArticlePageRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

export type HelpCenterRelatedArticlesListModelFilter = {
    readonly _createdAt?: Maybe<CreatedAtFilter>;
    readonly createdAt?: Maybe<CreatedAtFilter>;
    readonly id?: Maybe<ItemIdFilter>;
    readonly _firstPublishedAt?: Maybe<PublishedAtFilter>;
    readonly _publicationScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _publishedAt?: Maybe<PublishedAtFilter>;
    readonly _status?: Maybe<StatusFilter>;
    readonly _updatedAt?: Maybe<UpdatedAtFilter>;
    readonly updatedAt?: Maybe<UpdatedAtFilter>;
    readonly _isValid?: Maybe<BooleanFilter>;
    readonly relatedArticles?: Maybe<LinksFilter>;
    readonly pageName?: Maybe<StringFilter>;
    readonly pageId?: Maybe<StringFilter>;
    readonly OR?: Maybe<
        ReadonlyArray<Maybe<HelpCenterRelatedArticlesListModelFilter>>
    >;
};

export enum HelpCenterRelatedArticlesListModelOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
    _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
    _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
    _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
    _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
    _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
    _publishedAt_ASC = '_publishedAt_ASC',
    _publishedAt_DESC = '_publishedAt_DESC',
    _status_ASC = '_status_ASC',
    _status_DESC = '_status_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    _isValid_ASC = '_isValid_ASC',
    _isValid_DESC = '_isValid_DESC',
    pageName_ASC = 'pageName_ASC',
    pageName_DESC = 'pageName_DESC',
    pageId_ASC = 'pageId_ASC',
    pageId_DESC = 'pageId_DESC',
}

/** Record of type Help center - Related Arcticles (help_center_related_articles_list) */
export type HelpCenterRelatedArticlesListRecord = {
    readonly __typename?: 'HelpCenterRelatedArticlesListRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly pageId?: Maybe<Scalars['String']>;
    readonly pageName?: Maybe<Scalars['String']>;
    readonly relatedArticles: ReadonlyArray<HelpCenterArticleRecord>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Help center - Related Arcticles (help_center_related_articles_list) */
export type HelpCenterRelatedArticlesListRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

export type HelpCenterTopicModelFilter = {
    readonly _createdAt?: Maybe<CreatedAtFilter>;
    readonly createdAt?: Maybe<CreatedAtFilter>;
    readonly id?: Maybe<ItemIdFilter>;
    readonly _firstPublishedAt?: Maybe<PublishedAtFilter>;
    readonly position?: Maybe<PositionFilter>;
    readonly _publicationScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _publishedAt?: Maybe<PublishedAtFilter>;
    readonly _status?: Maybe<StatusFilter>;
    readonly _updatedAt?: Maybe<UpdatedAtFilter>;
    readonly updatedAt?: Maybe<UpdatedAtFilter>;
    readonly _isValid?: Maybe<BooleanFilter>;
    readonly url?: Maybe<SlugFilter>;
    readonly languageCopy?: Maybe<JsonFilter>;
    readonly name?: Maybe<StringFilter>;
    readonly description?: Maybe<TextFilter>;
    readonly OR?: Maybe<ReadonlyArray<Maybe<HelpCenterTopicModelFilter>>>;
};

export enum HelpCenterTopicModelOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
    _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
    position_ASC = 'position_ASC',
    position_DESC = 'position_DESC',
    _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
    _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
    _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
    _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
    _publishedAt_ASC = '_publishedAt_ASC',
    _publishedAt_DESC = '_publishedAt_DESC',
    _status_ASC = '_status_ASC',
    _status_DESC = '_status_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    _isValid_ASC = '_isValid_ASC',
    _isValid_DESC = '_isValid_DESC',
    name_ASC = 'name_ASC',
    name_DESC = 'name_DESC',
}

/** Record of type Help center - Topic (help_center_topic) */
export type HelpCenterTopicRecord = {
    readonly __typename?: 'HelpCenterTopicRecord';
    readonly _allDescriptionLocales?: Maybe<
        ReadonlyArray<Maybe<StringMultiLocaleField>>
    >;
    readonly _allNameLocales?: Maybe<
        ReadonlyArray<Maybe<StringMultiLocaleField>>
    >;
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly description?: Maybe<Scalars['String']>;
    readonly id: Scalars['ItemId'];
    readonly languageCopy?: Maybe<Scalars['JsonField']>;
    readonly name?: Maybe<Scalars['String']>;
    readonly position?: Maybe<Scalars['IntType']>;
    readonly updatedAt: Scalars['DateTime'];
    readonly url?: Maybe<Scalars['String']>;
};

/** Record of type Help center - Topic (help_center_topic) */
export type HelpCenterTopicRecord_AllDescriptionLocalesArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Topic (help_center_topic) */
export type HelpCenterTopicRecord_AllNameLocalesArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Topic (help_center_topic) */
export type HelpCenterTopicRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Topic (help_center_topic) */
export type HelpCenterTopicRecordDescriptionArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<SiteLocale>;
};

/** Record of type Help center - Topic (help_center_topic) */
export type HelpCenterTopicRecordNameArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Image (image) */
export type ImageRecord = {
    readonly __typename?: 'ImageRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly caption?: Maybe<Scalars['String']>;
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly image?: Maybe<FileField>;
    readonly showAsThumbnail?: Maybe<Scalars['BooleanType']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Image (image) */
export type ImageRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

export type ImgixParams = {
    /**
     * Aspect Ratio
     *
     * Specifies an aspect ratio to maintain when resizing and cropping the image
     *
     * Depends on: `fit=crop`
     */
    readonly ar?: Maybe<Scalars['String']>;
    /**
     * Automatic
     *
     * Applies automatic enhancements to images.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
     */
    readonly auto?: Maybe<ReadonlyArray<ImgixParamsAuto>>;
    /**
     * Background Color
     *
     * Colors the background of padded and partially-transparent images.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
     */
    readonly bg?: Maybe<Scalars['String']>;
    /**
     * Blend Align
     *
     * Changes the blend alignment relative to the parent image.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
     */
    readonly blendAlign?: Maybe<ReadonlyArray<ImgixParamsBlendAlign>>;
    /**
     * Blend Alpha
     *
     * Changes the alpha of the blend image.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
     */
    readonly blendAlpha?: Maybe<Scalars['IntType']>;
    /**
     * Blend Color
     *
     * Specifies a color to use when applying the blend.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
     */
    readonly blendColor?: Maybe<Scalars['String']>;
    /**
     * Blend Crop
     *
     * Specifies the type of crop for blend images.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
     */
    readonly blendCrop?: Maybe<ReadonlyArray<ImgixParamsBlendCrop>>;
    /**
     * Blend Fit
     *
     * Specifies the fit mode for blend images.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
     */
    readonly blendFit?: Maybe<ImgixParamsBlendFit>;
    /**
     * Blend Height
     *
     * Adjusts the height of the blend image.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
     */
    readonly blendH?: Maybe<Scalars['FloatType']>;
    /**
     * Blend Mode
     *
     * Sets the blend mode for a blend image.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
     */
    readonly blendMode?: Maybe<ImgixParamsBlendMode>;
    /**
     * Blend Padding
     *
     * Applies padding to the blend image.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
     */
    readonly blendPad?: Maybe<Scalars['IntType']>;
    /**
     * Blend Size
     *
     * Adjusts the size of the blend image.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
     */
    readonly blendSize?: Maybe<ImgixParamsBlendSize>;
    /**
     * Blend Width
     *
     * Adjusts the width of the blend image.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
     */
    readonly blendW?: Maybe<Scalars['FloatType']>;
    /**
     * Blend X Position
     *
     * Adjusts the x-offset of the blend image relative to its parent.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
     */
    readonly blendX?: Maybe<Scalars['IntType']>;
    /**
     * Blend Y Position
     *
     * Adjusts the y-offset of the blend image relative to its parent.
     *
     * Depends on: `blend`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
     */
    readonly blendY?: Maybe<Scalars['IntType']>;
    /**
     * Blend
     *
     * Specifies the location of the blend image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
     */
    readonly blend?: Maybe<Scalars['String']>;
    /**
     * Gaussian Blur
     *
     * Applies a gaussian blur to an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
     */
    readonly blur?: Maybe<Scalars['IntType']>;
    /**
     * Border Bottom
     *
     * Sets bottom border of an image.
     *
     * Depends on: `border`
     */
    readonly borderBottom?: Maybe<Scalars['IntType']>;
    /**
     * Border Left
     *
     * Sets left border of an image.
     *
     * Depends on: `border`
     */
    readonly borderLeft?: Maybe<Scalars['IntType']>;
    /**
     * Inner Border Radius
     *
     * Sets the inner radius of the image's border in pixels.
     *
     * Depends on: `border`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
     */
    readonly borderRadiusInner?: Maybe<Scalars['String']>;
    /**
     * Outer Border Radius
     *
     * Sets the outer radius of the image's border in pixels.
     *
     * Depends on: `border`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
     */
    readonly borderRadius?: Maybe<Scalars['String']>;
    /**
     * Border Right
     *
     * Sets right border of an image.
     *
     * Depends on: `border`
     */
    readonly borderRight?: Maybe<Scalars['IntType']>;
    /**
     * Border Top
     *
     * Sets top border of an image.
     *
     * Depends on: `border`
     */
    readonly borderTop?: Maybe<Scalars['IntType']>;
    /**
     * Border Size & Color
     *
     * Applies a border to an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
     */
    readonly border?: Maybe<Scalars['String']>;
    /**
     * Brightness
     *
     * Adjusts the brightness of the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
     */
    readonly bri?: Maybe<Scalars['IntType']>;
    /**
     * Client Hints
     *
     * Sets one or more Client-Hints headers
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
     */
    readonly ch?: Maybe<ReadonlyArray<ImgixParamsCh>>;
    /**
     * Chroma Subsampling
     *
     * Specifies the output chroma subsampling rate.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
     */
    readonly chromasub?: Maybe<Scalars['IntType']>;
    /**
     * Color Quantization
     *
     * Limits the number of unique colors in an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
     */
    readonly colorquant?: Maybe<Scalars['IntType']>;
    /**
     * Palette Color Count
     *
     * Specifies how many colors to include in a palette-extraction response.
     *
     * Depends on: `palette`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
     */
    readonly colors?: Maybe<Scalars['IntType']>;
    /**
     * Contrast
     *
     * Adjusts the contrast of the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
     */
    readonly con?: Maybe<Scalars['IntType']>;
    /**
     * Mask Corner Radius
     *
     * Specifies the radius value for a rounded corner mask.
     *
     * Depends on: `mask=corners`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
     */
    readonly cornerRadius?: Maybe<Scalars['String']>;
    /**
     * Crop Mode
     *
     * Specifies how to crop an image.
     *
     * Depends on: `fit=crop`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
     */
    readonly crop?: Maybe<ReadonlyArray<ImgixParamsCrop>>;
    /**
     * Color Space
     *
     * Specifies the color space of the output image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
     */
    readonly cs?: Maybe<ImgixParamsCs>;
    /**
     * Download
     *
     * Forces a URL to use send-file in its response.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
     */
    readonly dl?: Maybe<Scalars['String']>;
    /**
     * Dots Per Inch
     *
     * Sets the DPI value in the EXIF header.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
     */
    readonly dpi?: Maybe<Scalars['IntType']>;
    /**
     * Device Pixel Ratio
     *
     * Adjusts the device-pixel ratio of the output image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
     */
    readonly dpr?: Maybe<Scalars['FloatType']>;
    /**
     * Duotone Alpha
     *
     * Changes the alpha of the duotone effect atop the source image.
     *
     * Depends on: `duotone`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
     */
    readonly duotoneAlpha?: Maybe<Scalars['IntType']>;
    /**
     * Duotone
     *
     * Applies a duotone effect to the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
     */
    readonly duotone?: Maybe<Scalars['String']>;
    /**
     * Exposure
     *
     * Adjusts the exposure of the output image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
     */
    readonly exp?: Maybe<Scalars['IntType']>;
    /**
     * Url Expiration Timestamp
     *
     * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
     */
    readonly expires?: Maybe<Scalars['IntType']>;
    /**
     * Face Index
     *
     * Selects a face to crop to.
     *
     * Depends on: `fit=facearea`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
     */
    readonly faceindex?: Maybe<Scalars['IntType']>;
    /**
     * Face Padding
     *
     * Adjusts padding around a selected face.
     *
     * Depends on: `fit=facearea`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
     */
    readonly facepad?: Maybe<Scalars['FloatType']>;
    /**
     * Json Face Data
     *
     * Specifies that face data should be included in output when combined with `fm=json`.
     *
     * Depends on: `fm=json`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
     */
    readonly faces?: Maybe<Scalars['IntType']>;
    /**
     * Fill Color
     *
     * Sets the fill color for images with additional space created by the fit setting
     *
     * Depends on: `fill=solid`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
     */
    readonly fillColor?: Maybe<Scalars['String']>;
    /**
     * Fill Mode
     *
     * Determines how to fill in additional space created by the fit setting
     *
     * Depends on: `fit`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
     */
    readonly fill?: Maybe<ImgixParamsFill>;
    /**
     * Resize Fit Mode
     *
     * Specifies how to map the source image to the output image dimensions.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
     */
    readonly fit?: Maybe<ImgixParamsFit>;
    /**
     * Flip Axis
     *
     * Flips an image on a specified axis.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
     */
    readonly flip?: Maybe<ImgixParamsFlip>;
    /**
     * Output Format
     *
     * Changes the format of the output image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
     */
    readonly fm?: Maybe<ImgixParamsFm>;
    /**
     * Focal Point Debug
     *
     * Displays crosshairs identifying the location of the set focal point
     *
     * Depends on: `fit=crop`, `crop=focalpoint`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
     */
    readonly fpDebug?: Maybe<Scalars['BooleanType']>;
    /**
     * Focal Point X Position
     *
     * Sets the relative horizontal value for the focal point of an image
     *
     * Depends on: `fit=crop`, `crop=focalpoint`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
     */
    readonly fpX?: Maybe<Scalars['FloatType']>;
    /**
     * Focal Point Y Position
     *
     * Sets the relative vertical value for the focal point of an image
     *
     * Depends on: `fit=crop`, `crop=focalpoint`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
     */
    readonly fpY?: Maybe<Scalars['FloatType']>;
    /**
     * Focal Point Zoom
     *
     * Sets the relative zoom value for the focal point of an image
     *
     * Depends on: `fit=crop`, `crop=focalpoint`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
     */
    readonly fpZ?: Maybe<Scalars['IntType']>;
    /**
     * Gamma
     *
     * Adjusts the gamma of the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
     */
    readonly gam?: Maybe<Scalars['IntType']>;
    /**
     * Grid Colors
     *
     * Sets grid colors for the transparency checkerboard grid.
     *
     * Depends on: `transparency`
     */
    readonly gridColors?: Maybe<Scalars['String']>;
    /**
     * Grid Size
     *
     * Sets grid size for the transparency checkerboard grid.
     *
     * Depends on: `transparency`
     */
    readonly gridSize?: Maybe<Scalars['IntType']>;
    /**
     * Image Height
     *
     * Adjusts the height of the output image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
     */
    readonly h?: Maybe<Scalars['FloatType']>;
    /**
     * Highlight
     *
     * Adjusts the highlights of the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
     */
    readonly high?: Maybe<Scalars['IntType']>;
    /**
     * Halftone
     *
     * Applies a half-tone effect to the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
     */
    readonly htn?: Maybe<Scalars['IntType']>;
    /**
     * Hue Shift
     *
     * Adjusts the hue of the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
     */
    readonly hue?: Maybe<Scalars['IntType']>;
    /**
     * Invert
     *
     * Inverts the colors on the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
     */
    readonly invert?: Maybe<Scalars['BooleanType']>;
    /**
     * Lossless Compression
     *
     * Specifies that the output image should be a lossless variant.
     *
     * Depends on: `fm=webp`, `fm=jxr`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
     */
    readonly lossless?: Maybe<Scalars['BooleanType']>;
    /**
     * Watermark Alignment Mode
     *
     * Changes the watermark alignment relative to the parent image.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
     */
    readonly markAlign?: Maybe<ReadonlyArray<ImgixParamsMarkAlign>>;
    /**
     * Watermark Alpha
     *
     * Changes the alpha of the watermark image.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
     */
    readonly markAlpha?: Maybe<Scalars['IntType']>;
    /**
     * Watermark Base Url
     *
     * Changes base URL of the watermark image.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
     */
    readonly markBase?: Maybe<Scalars['String']>;
    /**
     * Watermark Fit Mode
     *
     * Specifies the fit mode for watermark images.
     *
     * Depends on: `mark`, `markw`, `markh`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
     */
    readonly markFit?: Maybe<ImgixParamsMarkFit>;
    /**
     * Watermark Height
     *
     * Adjusts the height of the watermark image.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
     */
    readonly markH?: Maybe<Scalars['FloatType']>;
    /**
     * Watermark Padding
     *
     * Applies padding to the watermark image.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
     */
    readonly markPad?: Maybe<Scalars['IntType']>;
    /**
     * Watermark Scale
     *
     * Adjusts the scale of the watermark image.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
     */
    readonly markScale?: Maybe<Scalars['IntType']>;
    /**
     * Watermark Width
     *
     * Adjusts the width of the watermark image.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
     */
    readonly markW?: Maybe<Scalars['FloatType']>;
    /**
     * Watermark X Position
     *
     * Adjusts the x-offset of the watermark image relative to its parent.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
     */
    readonly markX?: Maybe<Scalars['IntType']>;
    /**
     * Watermark Y Position
     *
     * Adjusts the y-offset of the watermark image relative to its parent.
     *
     * Depends on: `mark`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
     */
    readonly markY?: Maybe<Scalars['IntType']>;
    /**
     * Watermark Image Url
     *
     * Specifies the location of the watermark image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
     */
    readonly mark?: Maybe<Scalars['String']>;
    /**
     * Mask Background Color
     *
     * Colors the background of the transparent mask area of images
     *
     * Depends on: `mask`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
     */
    readonly maskBg?: Maybe<Scalars['String']>;
    /**
     * Mask Type
     *
     * Defines the type of mask and specifies the URL if that type is selected.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
     */
    readonly mask?: Maybe<Scalars['String']>;
    /**
     * Maximum Height
     *
     * Specifies the maximum height of the output image in pixels.
     *
     * Depends on: `fit=crop`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
     */
    readonly maxH?: Maybe<Scalars['IntType']>;
    /**
     * Maximum Width
     *
     * Specifies the maximum width of the output image in pixels.
     *
     * Depends on: `fit=crop`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
     */
    readonly maxW?: Maybe<Scalars['IntType']>;
    /**
     * Minimum Height
     *
     * Specifies the minimum height of the output image in pixels.
     *
     * Depends on: `fit=crop`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
     */
    readonly minH?: Maybe<Scalars['IntType']>;
    /**
     * Minimum Width
     *
     * Specifies the minimum width of the output image in pixels.
     *
     * Depends on: `fit=crop`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
     */
    readonly minW?: Maybe<Scalars['IntType']>;
    /**
     * Monochrome
     *
     * Applies a monochrome effect to the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
     */
    readonly monochrome?: Maybe<Scalars['String']>;
    /**
     * Noise Reduction Bound
     *
     * Reduces the noise in an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
     */
    readonly nr?: Maybe<Scalars['IntType']>;
    /**
     * Noise Reduction Sharpen
     *
     * Provides a threshold by which to sharpen an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
     */
    readonly nrs?: Maybe<Scalars['IntType']>;
    /**
     * Orientation
     *
     * Changes the image orientation.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
     */
    readonly orient?: Maybe<Scalars['IntType']>;
    /**
     * Padding Bottom
     *
     * Sets bottom padding of an image.
     */
    readonly padBottom?: Maybe<Scalars['IntType']>;
    /**
     * Padding Left
     *
     * Sets left padding of an image.
     */
    readonly padLeft?: Maybe<Scalars['IntType']>;
    /**
     * Padding Right
     *
     * Sets right padding of an image.
     */
    readonly padRight?: Maybe<Scalars['IntType']>;
    /**
     * Padding Top
     *
     * Sets top padding of an image.
     */
    readonly padTop?: Maybe<Scalars['IntType']>;
    /**
     * Padding
     *
     * Pads an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
     */
    readonly pad?: Maybe<Scalars['IntType']>;
    /**
     * Pdf Page Number
     *
     * Selects a page from a PDF for display.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf-page-number)
     */
    readonly page?: Maybe<Scalars['IntType']>;
    /**
     * Color Palette Extraction
     *
     * Specifies an output format for palette-extraction.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
     */
    readonly palette?: Maybe<ImgixParamsPalette>;
    /**
     * Css Prefix
     *
     * Specifies a CSS prefix for all classes in palette-extraction.
     *
     * Depends on: `palette=css`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
     */
    readonly prefix?: Maybe<Scalars['String']>;
    /**
     * Pixellate
     *
     * Applies a pixelation effect to an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
     */
    readonly px?: Maybe<Scalars['IntType']>;
    /**
     * Output Quality
     *
     * Adjusts the quality of an output image.
     *
     * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
     */
    readonly q?: Maybe<Scalars['IntType']>;
    /**
     * Source Rectangle Region
     *
     * Crops an image to a specified rectangle.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
     */
    readonly rect?: Maybe<Scalars['String']>;
    /**
     * Rotation
     *
     * Rotates an image by a specified number of degrees.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
     */
    readonly rot?: Maybe<Scalars['FloatType']>;
    /**
     * Saturation
     *
     * Adjusts the saturation of an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
     */
    readonly sat?: Maybe<Scalars['IntType']>;
    /**
     * Sepia Tone
     *
     * Applies a sepia effect to an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
     */
    readonly sepia?: Maybe<Scalars['IntType']>;
    /**
     * Shadow
     *
     * Adjusts the highlights of the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
     */
    readonly shad?: Maybe<Scalars['FloatType']>;
    /**
     * Sharpen
     *
     * Adjusts the sharpness of the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
     */
    readonly sharp?: Maybe<Scalars['FloatType']>;
    /**
     * Transparency
     *
     * Adds checkerboard behind images which support transparency.
     */
    readonly transparency?: Maybe<ImgixParamsTransparency>;
    /**
     * Trim Color
     *
     * Specifies a trim color on a trim operation.
     *
     * Depends on: `trim=color`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
     */
    readonly trimColor?: Maybe<Scalars['String']>;
    /**
     * Trim Mean Difference
     *
     * Specifies the mean difference on a trim operation.
     *
     * Depends on: `trim=auto`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
     */
    readonly trimMd?: Maybe<Scalars['FloatType']>;
    /**
     * Trim Padding
     *
     * Pads the area of the source image before trimming.
     *
     * Depends on: `trim`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
     */
    readonly trimPad?: Maybe<Scalars['IntType']>;
    /**
     * Trim Standard Deviation
     *
     * Specifies the standard deviation on a trim operation.
     *
     * Depends on: `trim=auto`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
     */
    readonly trimSd?: Maybe<Scalars['FloatType']>;
    /**
     * Trim Tolerance
     *
     * Specifies the tolerance on a trim operation.
     *
     * Depends on: `trim=color`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
     */
    readonly trimTol?: Maybe<Scalars['FloatType']>;
    /**
     * Trim Image
     *
     * Trims the source image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
     */
    readonly trim?: Maybe<ImgixParamsTrim>;
    /**
     * Text Align
     *
     * Sets the vertical and horizontal alignment of rendered text relative to the base image.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
     */
    readonly txtAlign?: Maybe<ReadonlyArray<ImgixParamsTxtAlign>>;
    /**
     * Text Clipping Mode
     *
     * Sets the clipping properties of rendered text.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
     */
    readonly txtClip?: Maybe<ReadonlyArray<ImgixParamsTxtClip>>;
    /**
     * Text Color
     *
     * Specifies the color of rendered text.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
     */
    readonly txtColor?: Maybe<Scalars['String']>;
    /**
     * Text Fit Mode
     *
     * Specifies the fit approach for rendered text.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
     */
    readonly txtFit?: Maybe<ImgixParamsTxtFit>;
    /**
     * Text Font
     *
     * Selects a font for rendered text.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
     */
    readonly txtFont?: Maybe<Scalars['String']>;
    /**
     * Text Leading
     *
     * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
     */
    readonly txtLead?: Maybe<Scalars['IntType']>;
    /**
     * Text Ligatures
     *
     * Controls the level of ligature substitution
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-lig)
     */
    readonly txtLig?: Maybe<Scalars['IntType']>;
    /**
     * Text Outline Color
     *
     * Specifies a text outline color.
     *
     * Depends on: `txt`, `txtline`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
     */
    readonly txtLineColor?: Maybe<Scalars['String']>;
    /**
     * Text Outline
     *
     * Outlines the rendered text with a specified color.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
     */
    readonly txtLine?: Maybe<Scalars['IntType']>;
    /**
     * Text Padding
     *
     * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
     */
    readonly txtPad?: Maybe<Scalars['IntType']>;
    /**
     * Text Shadow
     *
     * Applies a shadow to rendered text.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
     */
    readonly txtShad?: Maybe<Scalars['FloatType']>;
    /**
     * Text Font Size
     *
     * Sets the font size of rendered text.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
     */
    readonly txtSize?: Maybe<Scalars['IntType']>;
    /**
     * Text Tracking
     *
     * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
     */
    readonly txtTrack?: Maybe<Scalars['IntType']>;
    /**
     * Text Width
     *
     * Sets the width of rendered text.
     *
     * Depends on: `txt`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
     */
    readonly txtWidth?: Maybe<Scalars['IntType']>;
    /**
     * Text String
     *
     * Sets the text string to render.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
     */
    readonly txt?: Maybe<Scalars['String']>;
    /**
     * Unsharp Mask
     *
     * Sharpens the source image using an unsharp mask.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
     */
    readonly usm?: Maybe<Scalars['IntType']>;
    /**
     * Unsharp Mask Radius
     *
     * Specifies the radius for an unsharp mask operation.
     *
     * Depends on: `usm`
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
     */
    readonly usmrad?: Maybe<Scalars['FloatType']>;
    /**
     * Vibrance
     *
     * Adjusts the vibrance of an image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
     */
    readonly vib?: Maybe<Scalars['IntType']>;
    /**
     * Image Width
     *
     * Adjusts the width of the output image.
     *
     * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
     */
    readonly w?: Maybe<Scalars['FloatType']>;
};

export enum ImgixParamsAuto {
    enhance = 'enhance',
    format = 'format',
    redeye = 'redeye',
    compress = 'compress',
}

export enum ImgixParamsBlendAlign {
    top = 'top',
    bottom = 'bottom',
    middle = 'middle',
    left = 'left',
    right = 'right',
    center = 'center',
}

export enum ImgixParamsBlendCrop {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
    faces = 'faces',
}

export enum ImgixParamsBlendFit {
    clamp = 'clamp',
    clip = 'clip',
    crop = 'crop',
    scale = 'scale',
    max = 'max',
}

export enum ImgixParamsBlendMode {
    color = 'color',
    burn = 'burn',
    dodge = 'dodge',
    darken = 'darken',
    difference = 'difference',
    exclusion = 'exclusion',
    hardlight = 'hardlight',
    hue = 'hue',
    lighten = 'lighten',
    luminosity = 'luminosity',
    multiply = 'multiply',
    overlay = 'overlay',
    saturation = 'saturation',
    screen = 'screen',
    softlight = 'softlight',
    normal = 'normal',
}

export enum ImgixParamsBlendSize {
    inherit = 'inherit',
}

export enum ImgixParamsCh {
    width = 'width',
    dpr = 'dpr',
    saveData = 'saveData',
}

export enum ImgixParamsCrop {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
    faces = 'faces',
    entropy = 'entropy',
    edges = 'edges',
    focalpoint = 'focalpoint',
}

export enum ImgixParamsCs {
    srgb = 'srgb',
    adobergb1998 = 'adobergb1998',
    tinysrgb = 'tinysrgb',
    strip = 'strip',
}

export enum ImgixParamsFill {
    solid = 'solid',
    blur = 'blur',
}

export enum ImgixParamsFit {
    clamp = 'clamp',
    clip = 'clip',
    crop = 'crop',
    facearea = 'facearea',
    fill = 'fill',
    fillmax = 'fillmax',
    max = 'max',
    min = 'min',
    scale = 'scale',
}

export enum ImgixParamsFlip {
    h = 'h',
    v = 'v',
    hv = 'hv',
}

export enum ImgixParamsFm {
    gif = 'gif',
    jpg = 'jpg',
    jp2 = 'jp2',
    json = 'json',
    jxr = 'jxr',
    pjpg = 'pjpg',
    mp4 = 'mp4',
    png = 'png',
    png8 = 'png8',
    png32 = 'png32',
    webp = 'webp',
    webm = 'webm',
}

export enum ImgixParamsMarkAlign {
    top = 'top',
    middle = 'middle',
    bottom = 'bottom',
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum ImgixParamsMarkFit {
    clip = 'clip',
    crop = 'crop',
    fill = 'fill',
    max = 'max',
    scale = 'scale',
}

export enum ImgixParamsPalette {
    css = 'css',
    json = 'json',
}

export enum ImgixParamsTransparency {
    grid = 'grid',
}

export enum ImgixParamsTrim {
    auto = 'auto',
    color = 'color',
}

export enum ImgixParamsTxtAlign {
    top = 'top',
    middle = 'middle',
    bottom = 'bottom',
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum ImgixParamsTxtClip {
    start = 'start',
    middle = 'middle',
    end = 'end',
    ellipsis = 'ellipsis',
}

export enum ImgixParamsTxtFit {
    max = 'max',
}

/** Specifies how to filter by usage */
export type InUseFilter = {
    /** Search uploads that are currently used by some record or not */
    readonly eq?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
    /** Search the record with the specified ID */
    readonly eq?: Maybe<Scalars['ItemId']>;
    /** Exclude the record with the specified ID */
    readonly neq?: Maybe<Scalars['ItemId']>;
    /** Search records with the specified IDs */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
    /** Search records that do not have the specified IDs */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
};

export enum ItemStatus {
    draft = 'draft',
    updated = 'updated',
    published = 'published',
}

/** Specifies how to filter JSON fields */
export type JsonFilter = {
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
    /** Search for records with an exact match. The specified value must be a Record ID */
    readonly eq?: Maybe<Scalars['ItemId']>;
    /** Exclude records with an exact match. The specified value must be a Record ID */
    readonly neq?: Maybe<Scalars['ItemId']>;
    /** Filter records linked to one of the specified records */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
    /** Filter records not linked to one of the specified records */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
    /** Search for records with an exact match. The specified values must be Record IDs */
    readonly eq?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
    /** Filter records linked to all of the specified records. The specified values must be Record IDs */
    readonly allIn?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
    /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
    readonly anyIn?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
    /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['ItemId']>>>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

export enum MuxThumbnailFormatType {
    jpg = 'jpg',
    png = 'png',
    gif = 'gif',
}

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
    /** Search uploads with the specified orientation */
    readonly eq?: Maybe<UploadOrientation>;
    /** Exclude uploads with the specified orientation */
    readonly neq?: Maybe<UploadOrientation>;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
    /** Filter records with a value that's strictly greater than the one specified */
    readonly gt?: Maybe<Scalars['IntType']>;
    /** Filter records with a value that's less than the one specified */
    readonly lt?: Maybe<Scalars['IntType']>;
    /** Filter records with a value that's greater than or equal to the one specified */
    readonly gte?: Maybe<Scalars['IntType']>;
    /** Filter records with a value that's less or equal than the one specified */
    readonly lte?: Maybe<Scalars['IntType']>;
    /** Search for records with an exact match */
    readonly eq?: Maybe<Scalars['IntType']>;
    /** Exclude records with an exact match */
    readonly neq?: Maybe<Scalars['IntType']>;
};

/** Record of type Preamble (preamble) */
export type PreambleRecord = {
    readonly __typename?: 'PreambleRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly text?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Preamble (preamble) */
export type PreambleRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Preamble (preamble) */
export type PreambleRecordTextArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
    /** Filter records with a value that's strictly greater than the one specified */
    readonly gt?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's less than the one specified */
    readonly lt?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's greater than or equal to than the one specified */
    readonly gte?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's less or equal than the one specified */
    readonly lte?: Maybe<Scalars['DateTime']>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
    /** Search for records with an exact match (seconds are truncated) */
    readonly eq?: Maybe<Scalars['DateTime']>;
    /** Exclude records with an exact match */
    readonly neq?: Maybe<Scalars['DateTime']>;
};

/** The query root for this schema */
export type Query = {
    readonly __typename?: 'Query';
    /** Returns meta information regarding a record collection */
    readonly _allHelpCenterArticlesMeta: CollectionMetadata;
    /** Returns meta information regarding a record collection */
    readonly _allHelpCenterRelatedArticlePagesMeta: CollectionMetadata;
    /** Returns meta information regarding a record collection */
    readonly _allHelpCenterRelatedArticlesListsMeta: CollectionMetadata;
    /** Returns meta information regarding a record collection */
    readonly _allHelpCenterTopicsMeta: CollectionMetadata;
    /** Returns meta information regarding an assets collection */
    readonly _allUploadsMeta?: Maybe<CollectionMetadata>;
    /** Returns meta information regarding a record collection */
    readonly _allWebContactsMeta: CollectionMetadata;
    /** Returns meta information regarding a record collection */
    readonly _allWebLandingSectionsMeta: CollectionMetadata;
    /** Returns meta information regarding a record collection */
    readonly _allWebListsMeta: CollectionMetadata;
    /** Returns the single instance record */
    readonly _site: Site;
    /** Returns a collection of records */
    readonly allHelpCenterArticles: ReadonlyArray<HelpCenterArticleRecord>;
    /** Returns a collection of records */
    readonly allHelpCenterRelatedArticlePages: ReadonlyArray<HelpCenterRelatedArticlePageRecord>;
    /** Returns a collection of records */
    readonly allHelpCenterRelatedArticlesLists: ReadonlyArray<HelpCenterRelatedArticlesListRecord>;
    /** Returns a collection of records */
    readonly allHelpCenterTopics: ReadonlyArray<HelpCenterTopicRecord>;
    /** Returns a collection of assets */
    readonly allUploads: ReadonlyArray<FileField>;
    /** Returns a collection of records */
    readonly allWebContacts: ReadonlyArray<WebContactRecord>;
    /** Returns a collection of records */
    readonly allWebLandingSections: ReadonlyArray<WebLandingSectionRecord>;
    /** Returns a collection of records */
    readonly allWebLists: ReadonlyArray<WebListRecord>;
    /** Returns a specific record */
    readonly helpCenterArticle?: Maybe<HelpCenterArticleRecord>;
    /** Returns a specific record */
    readonly helpCenterRelatedArticlePage?: Maybe<HelpCenterRelatedArticlePageRecord>;
    /** Returns a specific record */
    readonly helpCenterRelatedArticlesList?: Maybe<HelpCenterRelatedArticlesListRecord>;
    /** Returns a specific record */
    readonly helpCenterTopic?: Maybe<HelpCenterTopicRecord>;
    /** Returns a specific asset */
    readonly upload?: Maybe<FileField>;
    /** Returns a specific record */
    readonly webContact?: Maybe<WebContactRecord>;
    /** Returns a specific record */
    readonly webLandingSection?: Maybe<WebLandingSectionRecord>;
    /** Returns a specific record */
    readonly webList?: Maybe<WebListRecord>;
};

/** The query root for this schema */
export type Query_AllHelpCenterArticlesMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterArticleModelFilter>;
};

/** The query root for this schema */
export type Query_AllHelpCenterRelatedArticlePagesMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterRelatedArticlePageModelFilter>;
};

/** The query root for this schema */
export type Query_AllHelpCenterRelatedArticlesListsMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterRelatedArticlesListModelFilter>;
};

/** The query root for this schema */
export type Query_AllHelpCenterTopicsMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterTopicModelFilter>;
};

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<UploadFilter>;
};

/** The query root for this schema */
export type Query_AllWebContactsMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<WebContactModelFilter>;
};

/** The query root for this schema */
export type Query_AllWebLandingSectionsMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<WebLandingSectionModelFilter>;
};

/** The query root for this schema */
export type Query_AllWebListsMetaArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<WebListModelFilter>;
};

/** The query root for this schema */
export type Query_SiteArgs = {
    locale?: Maybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAllHelpCenterArticlesArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<HelpCenterArticleModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<HelpCenterArticleModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllHelpCenterRelatedArticlePagesArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<HelpCenterRelatedArticlePageModelFilter>;
    orderBy?: Maybe<
        ReadonlyArray<Maybe<HelpCenterRelatedArticlePageModelOrderBy>>
    >;
};

/** The query root for this schema */
export type QueryAllHelpCenterRelatedArticlesListsArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<HelpCenterRelatedArticlesListModelFilter>;
    orderBy?: Maybe<
        ReadonlyArray<Maybe<HelpCenterRelatedArticlesListModelOrderBy>>
    >;
};

/** The query root for this schema */
export type QueryAllHelpCenterTopicsArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<HelpCenterTopicModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<HelpCenterTopicModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllUploadsArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<UploadFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<UploadOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllWebContactsArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<WebContactModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<WebContactModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllWebLandingSectionsArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<WebLandingSectionModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<WebLandingSectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryAllWebListsArgs = {
    locale?: Maybe<SiteLocale>;
    skip?: Maybe<Scalars['IntType']>;
    first?: Maybe<Scalars['IntType']>;
    filter?: Maybe<WebListModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<WebListModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryHelpCenterArticleArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterArticleModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<HelpCenterArticleModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryHelpCenterRelatedArticlePageArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterRelatedArticlePageModelFilter>;
    orderBy?: Maybe<
        ReadonlyArray<Maybe<HelpCenterRelatedArticlePageModelOrderBy>>
    >;
};

/** The query root for this schema */
export type QueryHelpCenterRelatedArticlesListArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterRelatedArticlesListModelFilter>;
    orderBy?: Maybe<
        ReadonlyArray<Maybe<HelpCenterRelatedArticlesListModelOrderBy>>
    >;
};

/** The query root for this schema */
export type QueryHelpCenterTopicArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<HelpCenterTopicModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<HelpCenterTopicModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryUploadArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<UploadFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<UploadOrderBy>>>;
};

/** The query root for this schema */
export type QueryWebContactArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<WebContactModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<WebContactModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryWebLandingSectionArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<WebLandingSectionModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<WebLandingSectionModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryWebListArgs = {
    locale?: Maybe<SiteLocale>;
    filter?: Maybe<WebListModelFilter>;
    orderBy?: Maybe<ReadonlyArray<Maybe<WebListModelOrderBy>>>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
    /** Search uploads with the specified resolution */
    readonly eq?: Maybe<ResolutionType>;
    /** Exclude uploads with the specified resolution */
    readonly neq?: Maybe<ResolutionType>;
    /** Search uploads with the specified resolutions */
    readonly in?: Maybe<ReadonlyArray<Maybe<ResolutionType>>>;
    /** Search uploads without the specified resolutions */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<ResolutionType>>>;
};

export enum ResolutionType {
    icon = 'icon',
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export type ResponsiveImage = {
    readonly __typename?: 'ResponsiveImage';
    readonly alt?: Maybe<Scalars['String']>;
    readonly aspectRatio: Scalars['FloatType'];
    readonly base64?: Maybe<Scalars['String']>;
    readonly bgColor?: Maybe<Scalars['String']>;
    readonly height: Scalars['IntType'];
    readonly sizes: Scalars['String'];
    readonly src: Scalars['String'];
    readonly srcSet: Scalars['String'];
    readonly title?: Maybe<Scalars['String']>;
    readonly webpSrcSet: Scalars['String'];
    readonly width: Scalars['IntType'];
};

export type SeoField = {
    readonly __typename?: 'SeoField';
    readonly description?: Maybe<Scalars['String']>;
    readonly image?: Maybe<FileField>;
    readonly title?: Maybe<Scalars['String']>;
    readonly twitterCard?: Maybe<Scalars['String']>;
};

export type Site = {
    readonly __typename?: 'Site';
    readonly favicon?: Maybe<FileField>;
    readonly faviconMetaTags: ReadonlyArray<Tag>;
    readonly globalSeo?: Maybe<GlobalSeoField>;
};

export type SiteFaviconMetaTagsArgs = {
    variants?: Maybe<ReadonlyArray<Maybe<FaviconType>>>;
};

export type SiteGlobalSeoArgs = {
    locale?: Maybe<SiteLocale>;
};

export enum SiteLocale {
    en_GB = 'en_GB',
    sv_SE = 'sv_SE',
}

/** Specifies how to filter Slug fields */
export type SlugFilter = {
    /** Search for records with an exact match */
    readonly eq?: Maybe<Scalars['String']>;
    /** Exclude records with an exact match */
    readonly neq?: Maybe<Scalars['String']>;
    /** Filter records that have one of the specified slugs */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Filter records that do have one of the specified slugs */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
    /** Search the record with the specified status */
    readonly eq?: Maybe<ItemStatus>;
    /** Exclude the record with the specified status */
    readonly neq?: Maybe<ItemStatus>;
    /** Search records with the specified statuses */
    readonly in?: Maybe<ReadonlyArray<Maybe<ItemStatus>>>;
    /** Search records without the specified statuses */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<ItemStatus>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
    /** Filter records based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude records based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
    /** Search for records with an exact match */
    readonly eq?: Maybe<Scalars['String']>;
    /** Exclude records with an exact match */
    readonly neq?: Maybe<Scalars['String']>;
    /** Filter records that equal one of the specified values */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Filter records that do not equal one of the specified values */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type StringMatchesFilter = {
    readonly pattern: Scalars['String'];
    readonly caseSensitive?: Maybe<Scalars['BooleanType']>;
    readonly regexp?: Maybe<Scalars['BooleanType']>;
};

export type StringMultiLocaleField = {
    readonly __typename?: 'StringMultiLocaleField';
    readonly locale?: Maybe<SiteLocale>;
    readonly value?: Maybe<Scalars['String']>;
};

/** Specifies how to filter Structured Text fields */
export type StructuredTextFilter = {
    /** Filter records based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude records based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

export type Tag = {
    readonly __typename?: 'Tag';
    readonly attributes?: Maybe<Scalars['MetaTagAttributes']>;
    readonly content?: Maybe<Scalars['String']>;
    readonly tag: Scalars['String'];
};

/** Specifies how to filter text fields */
export type TextFilter = {
    /** Filter records based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude records based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

/** Record of type Text (text) */
export type TextRecord = {
    readonly __typename?: 'TextRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly text?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Text (text) */
export type TextRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Text (text) */
export type TextRecordTextArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
    /** Search uploads with the specified type */
    readonly eq?: Maybe<UploadType>;
    /** Exclude uploads with the specified type */
    readonly neq?: Maybe<UploadType>;
    /** Search uploads with the specified types */
    readonly in?: Maybe<ReadonlyArray<Maybe<UploadType>>>;
    /** Search uploads without the specified types */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
    /** Filter records with a value that's strictly greater than the one specified */
    readonly gt?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's less than the one specified */
    readonly lt?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's greater than or equal to than the one specified */
    readonly gte?: Maybe<Scalars['DateTime']>;
    /** Filter records with a value that's less or equal than the one specified */
    readonly lte?: Maybe<Scalars['DateTime']>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
    /** Search for records with an exact match (seconds are truncated) */
    readonly eq?: Maybe<Scalars['DateTime']>;
    /** Exclude records with an exact match */
    readonly neq?: Maybe<Scalars['DateTime']>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Search the uploads with the specified alt */
    readonly eq?: Maybe<Scalars['String']>;
    /** Exclude the uploads with the specified alt */
    readonly neq?: Maybe<Scalars['String']>;
    /** Search uploads with the specified values as default alt */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Search uploads that do not have the specified values as default alt */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Filter uploads with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Filter uploads with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
    /** Filter uploads that have the specified colors */
    readonly contains?: Maybe<ColorBucketType>;
    /** Filter uploads that have all of the specified colors */
    readonly allIn?: Maybe<ReadonlyArray<Maybe<ColorBucketType>>>;
    /** Filter uploads that have at least one of the specified colors */
    readonly anyIn?: Maybe<ReadonlyArray<Maybe<ColorBucketType>>>;
    /** Filter uploads that do not have any of the specified colors */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<ColorBucketType>>>;
    /** Search for uploads with an exact match */
    readonly eq?: Maybe<ReadonlyArray<Maybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
    /** Search for uploads with an exact match */
    readonly eq?: Maybe<Scalars['DateTime']>;
    /** Exclude uploads with an exact match */
    readonly neq?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's less than the one specified */
    readonly lt?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's less or equal than the one specified */
    readonly lte?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's strictly greater than the one specified */
    readonly gt?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's greater than or equal to the one specified */
    readonly gte?: Maybe<Scalars['DateTime']>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
};

export type UploadFilter = {
    readonly type?: Maybe<TypeFilter>;
    readonly inUse?: Maybe<InUseFilter>;
    readonly resolution?: Maybe<ResolutionFilter>;
    readonly size?: Maybe<UploadSizeFilter>;
    readonly tags?: Maybe<UploadTagsFilter>;
    readonly smartTags?: Maybe<UploadTagsFilter>;
    readonly colors?: Maybe<UploadColorsFilter>;
    readonly orientation?: Maybe<OrientationFilter>;
    readonly id?: Maybe<UploadIdFilter>;
    readonly mimeType?: Maybe<UploadMimeTypeFilter>;
    readonly format?: Maybe<UploadFormatFilter>;
    readonly height?: Maybe<UploadHeightFilter>;
    readonly width?: Maybe<UploadWidthFilter>;
    readonly alt?: Maybe<UploadAltFilter>;
    readonly title?: Maybe<UploadTitleFilter>;
    readonly notes?: Maybe<UploadNotesFilter>;
    readonly author?: Maybe<UploadAuthorFilter>;
    readonly copyright?: Maybe<UploadCopyrightFilter>;
    readonly basename?: Maybe<UploadBasenameFilter>;
    readonly filename?: Maybe<UploadFilenameFilter>;
    readonly _createdAt?: Maybe<UploadCreatedAtFilter>;
    readonly _updatedAt?: Maybe<UploadUpdatedAtFilter>;
    readonly OR?: Maybe<ReadonlyArray<Maybe<UploadFilter>>>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
    /** Search the asset with the specified format */
    readonly eq?: Maybe<Scalars['String']>;
    /** Exclude the asset with the specified format */
    readonly neq?: Maybe<Scalars['String']>;
    /** Search assets with the specified formats */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Search assets that do not have the specified formats */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
    /** Search all assets larger than the specified height */
    readonly gt?: Maybe<Scalars['IntType']>;
    /** Search all assets smaller than the specified height */
    readonly lt?: Maybe<Scalars['IntType']>;
    /** Search all assets larger or equal to the specified height */
    readonly gte?: Maybe<Scalars['IntType']>;
    /** Search all assets larger or equal to the specified height */
    readonly lte?: Maybe<Scalars['IntType']>;
    /** Search assets with the specified height */
    readonly eq?: Maybe<Scalars['IntType']>;
    /** Search assets that do not have the specified height */
    readonly neq?: Maybe<Scalars['IntType']>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
    /** Search the asset with the specified ID */
    readonly eq?: Maybe<Scalars['UploadId']>;
    /** Exclude the asset with the specified ID */
    readonly neq?: Maybe<Scalars['UploadId']>;
    /** Search assets with the specified IDs */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['UploadId']>>>;
    /** Search assets that do not have the specified IDs */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['UploadId']>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Search the asset with the specified mime type */
    readonly eq?: Maybe<Scalars['String']>;
    /** Exclude the asset with the specified mime type */
    readonly neq?: Maybe<Scalars['String']>;
    /** Search assets with the specified mime types */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Search assets that do not have the specified mime types */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Filter records with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

export enum UploadOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    size_ASC = 'size_ASC',
    size_DESC = 'size_DESC',
    resolution_ASC = 'resolution_ASC',
    resolution_DESC = 'resolution_DESC',
    filename_ASC = 'filename_ASC',
    filename_DESC = 'filename_DESC',
    basename_ASC = 'basename_ASC',
    basename_DESC = 'basename_DESC',
    mimeType_ASC = 'mimeType_ASC',
    mimeType_DESC = 'mimeType_DESC',
    format_ASC = 'format_ASC',
    format_DESC = 'format_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
}

export enum UploadOrientation {
    landscape = 'landscape',
    portrait = 'portrait',
    square = 'square',
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
    /** Search all assets larger than the specified size */
    readonly gt?: Maybe<Scalars['IntType']>;
    /** Search all assets smaller than the specified size */
    readonly lt?: Maybe<Scalars['IntType']>;
    /** Search all assets larger or equal to the specified size */
    readonly gte?: Maybe<Scalars['IntType']>;
    /** Search all assets larger or equal to the specified size */
    readonly lte?: Maybe<Scalars['IntType']>;
    /** Search assets with the specified size */
    readonly eq?: Maybe<Scalars['IntType']>;
    /** Search assets that do not have the specified size */
    readonly neq?: Maybe<Scalars['IntType']>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
    /** Filter uploads linked to the specified tag */
    readonly contains?: Maybe<Scalars['String']>;
    /** Filter uploads linked to all of the specified tags */
    readonly allIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Filter uploads linked to at least one of the specified tags */
    readonly anyIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Filter uploads not linked to any of the specified tags */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Search for uploads with an exact match */
    readonly eq?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
    /** Filter uploads based on a regular expression */
    readonly matches?: Maybe<StringMatchesFilter>;
    /** Exclude uploads based on a regular expression */
    readonly notMatches?: Maybe<StringMatchesFilter>;
    /** Search the asset with the specified title */
    readonly eq?: Maybe<Scalars['String']>;
    /** Exclude the asset with the specified title */
    readonly neq?: Maybe<Scalars['String']>;
    /** Search assets with the specified as default title */
    readonly in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Search assets that do not have the specified as default title */
    readonly notIn?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    /** Filter assets with the specified field defined (i.e. with any value) or not */
    readonly exists?: Maybe<Scalars['BooleanType']>;
};

export enum UploadType {
    image = 'image',
    audio = 'audio',
    video = 'video',
    richtext = 'richtext',
    presentation = 'presentation',
    spreadsheet = 'spreadsheet',
    pdfdocument = 'pdfdocument',
    archive = 'archive',
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
    /** Search for uploads with an exact match */
    readonly eq?: Maybe<Scalars['DateTime']>;
    /** Exclude uploads with an exact match */
    readonly neq?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's less than the one specified */
    readonly lt?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's less or equal than the one specified */
    readonly lte?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's strictly greater than the one specified */
    readonly gt?: Maybe<Scalars['DateTime']>;
    /** Filter uploads with a value that's greater than or equal to the one specified */
    readonly gte?: Maybe<Scalars['DateTime']>;
};

export type UploadVideoField = {
    readonly __typename?: 'UploadVideoField';
    readonly duration: Scalars['Int'];
    readonly framerate: Scalars['Int'];
    readonly mp4Url?: Maybe<Scalars['String']>;
    readonly muxAssetId: Scalars['String'];
    readonly muxPlaybackId: Scalars['String'];
    readonly streamingUrl: Scalars['String'];
    readonly thumbnailUrl: Scalars['String'];
};

export type UploadVideoFieldMp4UrlArgs = {
    res?: Maybe<VideoMp4Res>;
    exactRes?: Maybe<VideoMp4Res>;
};

export type UploadVideoFieldThumbnailUrlArgs = {
    format?: Maybe<MuxThumbnailFormatType>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
    /** Search all assets larger than the specified width */
    readonly gt?: Maybe<Scalars['IntType']>;
    /** Search all assets smaller than the specified width */
    readonly lt?: Maybe<Scalars['IntType']>;
    /** Search all assets larger or equal to the specified width */
    readonly gte?: Maybe<Scalars['IntType']>;
    /** Search all assets larger or equal to the specified width */
    readonly lte?: Maybe<Scalars['IntType']>;
    /** Search assets with the specified width */
    readonly eq?: Maybe<Scalars['IntType']>;
    /** Search assets that do not have the specified width */
    readonly neq?: Maybe<Scalars['IntType']>;
};

export type VideoField = {
    readonly __typename?: 'VideoField';
    readonly height?: Maybe<Scalars['IntType']>;
    readonly provider?: Maybe<Scalars['String']>;
    readonly providerUid?: Maybe<Scalars['String']>;
    readonly thumbnailUrl?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
    readonly url?: Maybe<Scalars['String']>;
    readonly width?: Maybe<Scalars['IntType']>;
};

export enum VideoMp4Res {
    low = 'low',
    medium = 'medium',
    high = 'high',
}

/** Record of type Video (youtube) (video) */
export type VideoRecord = {
    readonly __typename?: 'VideoRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly caption?: Maybe<Scalars['String']>;
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly updatedAt: Scalars['DateTime'];
    readonly video?: Maybe<VideoField>;
};

/** Record of type Video (youtube) (video) */
export type VideoRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web Card (web_card) */
export type WebCardRecord = {
    readonly __typename?: 'WebCardRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly htmlformatted?: Maybe<Scalars['String']>;
    readonly id: Scalars['ItemId'];
    readonly link?: Maybe<Scalars['String']>;
    readonly logocolor?: Maybe<ColorField>;
    readonly media: ReadonlyArray<FileField>;
    readonly shortTitle?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web Card (web_card) */
export type WebCardRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web Card (web_card) */
export type WebCardRecordHtmlformattedArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
};

/** Record of type Web Card (web_card) */
export type WebCardRecordTitleArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
};

/** Record of type Web Contact List (web_contact_list) */
export type WebContactListRecord = {
    readonly __typename?: 'WebContactListRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly contact: ReadonlyArray<WebContactRecord>;
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web Contact List (web_contact_list) */
export type WebContactListRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

export type WebContactModelFilter = {
    readonly _createdAt?: Maybe<CreatedAtFilter>;
    readonly createdAt?: Maybe<CreatedAtFilter>;
    readonly id?: Maybe<ItemIdFilter>;
    readonly _firstPublishedAt?: Maybe<PublishedAtFilter>;
    readonly _publicationScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _publishedAt?: Maybe<PublishedAtFilter>;
    readonly _status?: Maybe<StatusFilter>;
    readonly _updatedAt?: Maybe<UpdatedAtFilter>;
    readonly updatedAt?: Maybe<UpdatedAtFilter>;
    readonly _isValid?: Maybe<BooleanFilter>;
    readonly email?: Maybe<StringFilter>;
    readonly phone?: Maybe<StringFilter>;
    readonly phoneTight?: Maybe<StringFilter>;
    readonly name?: Maybe<StringFilter>;
    readonly OR?: Maybe<ReadonlyArray<Maybe<WebContactModelFilter>>>;
};

export enum WebContactModelOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
    _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
    _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
    _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
    _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
    _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
    _publishedAt_ASC = '_publishedAt_ASC',
    _publishedAt_DESC = '_publishedAt_DESC',
    _status_ASC = '_status_ASC',
    _status_DESC = '_status_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    _isValid_ASC = '_isValid_ASC',
    _isValid_DESC = '_isValid_DESC',
    email_ASC = 'email_ASC',
    email_DESC = 'email_DESC',
    phone_ASC = 'phone_ASC',
    phone_DESC = 'phone_DESC',
    phoneTight_ASC = 'phoneTight_ASC',
    phoneTight_DESC = 'phoneTight_DESC',
    name_ASC = 'name_ASC',
    name_DESC = 'name_DESC',
}

/** Record of type Web Contact (web_contact) */
export type WebContactRecord = {
    readonly __typename?: 'WebContactRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly email?: Maybe<Scalars['String']>;
    readonly id: Scalars['ItemId'];
    readonly name?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly phoneTight?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web Contact (web_contact) */
export type WebContactRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web Html (web_html) */
export type WebHtmlRecord = {
    readonly __typename?: 'WebHtmlRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly htmlFormatted?: Maybe<Scalars['String']>;
    readonly id: Scalars['ItemId'];
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web Html (web_html) */
export type WebHtmlRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web Html (web_html) */
export type WebHtmlRecordHtmlFormattedArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
};

export type WebLandingSectionModelContentField =
    | WebCardRecord
    | WebContactListRecord
    | WebHtmlRecord
    | WebListBlockRecord
    | WebMediaContentRecord
    | WebQuoteListRecord;

export type WebLandingSectionModelFilter = {
    readonly _createdAt?: Maybe<CreatedAtFilter>;
    readonly createdAt?: Maybe<CreatedAtFilter>;
    readonly id?: Maybe<ItemIdFilter>;
    readonly _firstPublishedAt?: Maybe<PublishedAtFilter>;
    readonly _publicationScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _publishedAt?: Maybe<PublishedAtFilter>;
    readonly _status?: Maybe<StatusFilter>;
    readonly _updatedAt?: Maybe<UpdatedAtFilter>;
    readonly updatedAt?: Maybe<UpdatedAtFilter>;
    readonly _isValid?: Maybe<BooleanFilter>;
    readonly title?: Maybe<StringFilter>;
    readonly sectionId?: Maybe<StringFilter>;
    readonly titlePreview?: Maybe<StringFilter>;
    readonly externalLink?: Maybe<StringFilter>;
    readonly OR?: Maybe<ReadonlyArray<Maybe<WebLandingSectionModelFilter>>>;
};

export enum WebLandingSectionModelOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
    _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
    _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
    _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
    _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
    _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
    _publishedAt_ASC = '_publishedAt_ASC',
    _publishedAt_DESC = '_publishedAt_DESC',
    _status_ASC = '_status_ASC',
    _status_DESC = '_status_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    _isValid_ASC = '_isValid_ASC',
    _isValid_DESC = '_isValid_DESC',
    title_ASC = 'title_ASC',
    title_DESC = 'title_DESC',
    sectionId_ASC = 'sectionId_ASC',
    sectionId_DESC = 'sectionId_DESC',
    titlePreview_ASC = 'titlePreview_ASC',
    titlePreview_DESC = 'titlePreview_DESC',
    externalLink_ASC = 'externalLink_ASC',
    externalLink_DESC = 'externalLink_DESC',
}

/** Record of type Web Landing Section  (web_landing_section) */
export type WebLandingSectionRecord = {
    readonly __typename?: 'WebLandingSectionRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly content?: Maybe<
        ReadonlyArray<Maybe<WebLandingSectionModelContentField>>
    >;
    readonly createdAt: Scalars['DateTime'];
    readonly externalLink?: Maybe<Scalars['String']>;
    readonly id: Scalars['ItemId'];
    readonly sectionId?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
    readonly titlePreview?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web Landing Section  (web_landing_section) */
export type WebLandingSectionRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web List Block (web_list_block) */
export type WebListBlockRecord = {
    readonly __typename?: 'WebListBlockRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly item: ReadonlyArray<WebListRecord>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web List Block (web_list_block) */
export type WebListBlockRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

export type WebListModelFilter = {
    readonly _createdAt?: Maybe<CreatedAtFilter>;
    readonly createdAt?: Maybe<CreatedAtFilter>;
    readonly id?: Maybe<ItemIdFilter>;
    readonly _firstPublishedAt?: Maybe<PublishedAtFilter>;
    readonly _publicationScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
    readonly _publishedAt?: Maybe<PublishedAtFilter>;
    readonly _status?: Maybe<StatusFilter>;
    readonly _updatedAt?: Maybe<UpdatedAtFilter>;
    readonly updatedAt?: Maybe<UpdatedAtFilter>;
    readonly _isValid?: Maybe<BooleanFilter>;
    readonly body?: Maybe<TextFilter>;
    readonly OR?: Maybe<ReadonlyArray<Maybe<WebListModelFilter>>>;
};

export enum WebListModelOrderBy {
    _createdAt_ASC = '_createdAt_ASC',
    _createdAt_DESC = '_createdAt_DESC',
    createdAt_ASC = 'createdAt_ASC',
    createdAt_DESC = 'createdAt_DESC',
    id_ASC = 'id_ASC',
    id_DESC = 'id_DESC',
    _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
    _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
    _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
    _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
    _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
    _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
    _publishedAt_ASC = '_publishedAt_ASC',
    _publishedAt_DESC = '_publishedAt_DESC',
    _status_ASC = '_status_ASC',
    _status_DESC = '_status_DESC',
    _updatedAt_ASC = '_updatedAt_ASC',
    _updatedAt_DESC = '_updatedAt_DESC',
    updatedAt_ASC = 'updatedAt_ASC',
    updatedAt_DESC = 'updatedAt_DESC',
    _isValid_ASC = '_isValid_ASC',
    _isValid_DESC = '_isValid_DESC',
}

/** Record of type Web List (web_list) */
export type WebListRecord = {
    readonly __typename?: 'WebListRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly body?: Maybe<Scalars['String']>;
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web List (web_list) */
export type WebListRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web List (web_list) */
export type WebListRecordBodyArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
};

/** Record of type Web Media (web_media_content) */
export type WebMediaContentRecord = {
    readonly __typename?: 'WebMediaContentRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly id: Scalars['ItemId'];
    readonly updatedAt: Scalars['DateTime'];
    readonly url: ReadonlyArray<FileField>;
};

/** Record of type Web Media (web_media_content) */
export type WebMediaContentRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web Quote List (web_quote_list) */
export type WebQuoteListRecord = {
    readonly __typename?: 'WebQuoteListRecord';
    readonly _createdAt: Scalars['DateTime'];
    readonly _firstPublishedAt?: Maybe<Scalars['DateTime']>;
    readonly _isValid: Scalars['BooleanType'];
    readonly _modelApiKey: Scalars['String'];
    readonly _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _publishedAt?: Maybe<Scalars['DateTime']>;
    /** SEO meta tags */
    readonly _seoMetaTags: ReadonlyArray<Tag>;
    readonly _status: ItemStatus;
    readonly _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
    readonly _updatedAt: Scalars['DateTime'];
    readonly createdAt: Scalars['DateTime'];
    readonly htmlFormatted?: Maybe<Scalars['String']>;
    readonly id: Scalars['ItemId'];
    readonly media: ReadonlyArray<FileField>;
    readonly name?: Maybe<Scalars['String']>;
    readonly title?: Maybe<Scalars['String']>;
    readonly updatedAt: Scalars['DateTime'];
};

/** Record of type Web Quote List (web_quote_list) */
export type WebQuoteListRecord_SeoMetaTagsArgs = {
    locale?: Maybe<SiteLocale>;
};

/** Record of type Web Quote List (web_quote_list) */
export type WebQuoteListRecordHtmlFormattedArgs = {
    markdown?: Maybe<Scalars['Boolean']>;
};
