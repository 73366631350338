import { Grid, Typography, Skeleton, styled } from '@mui/material';

export function TopBarSkeleton() {
    return (
        <TopParContainer>
            <Grid
                container
                spacing={1}
                alignItems="center"
                style={{
                    paddingLeft: 12,
                }}
            >
                <Grid item>
                    <Typography component="div" variant="h4">
                        <Skeleton width={350} />
                    </Typography>
                </Grid>
                <Grid item>
                    <Skeleton variant="circular" width={20} height={20} />
                </Grid>
            </Grid>
            <div
                style={{
                    paddingRight: 12,
                }}
            >
                <Skeleton variant="circular" width={40} height={40} />
            </div>
        </TopParContainer>
    );
}

const TopParContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `8px 8px`,
    minHeight: 64,
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0 1px ${theme.bino.color.boxShadowBorder}`,
}));
