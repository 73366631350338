// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import './bootstrap';
//export { normalizeVisibilityConditionExpression } from './utils/normalizeVisibilityConditionExpression';

// // @ts-expect-error VITE is not using process to access env variable
// window.process = {
//     env: {
//         NODE_ENV: import.meta.env.MODE
//     }
// }

import { createRoot } from 'react-dom/client';
import App from './App';
import polyfillIntl from './polyfill/intl';
import { bootstrapI18n } from './i18n';
import './bootstrap-auth';

//import registerServiceWorker from './registerServiceWorker';

async function run() {
    const locale = await bootstrapI18n();
    await polyfillIntl(locale);
    const root = createRoot(document.getElementById('root'));
    root.render(<App />);
}

// if (import.meta.env.DEV) {
//     if (module.hot) {
//         module.hot.accept('./App', () => {
//             const NextApp = require('./App').default;
//             run(NextApp);
//         });
//     }
// }

run()
    .then(() => {
        // ... not sure if we need to do anything here
    })
    .catch((e) => {
        console.error(e);
    });
