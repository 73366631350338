import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { PageErrorBoundaryLayout } from '../PageErrorBoundaryLayout';
import { NotAuthenticatedError } from '../../RelayEnvironment';

export function PageContentErrorBoundary({
    children,
}: {
    children: ReactNode;
}) {
    const location = useLocation();
    return (
        <ErrorBoundary
            FallbackComponent={FallbackPage}
            resetKeys={[location.pathname]}
            onReset={() => {
                console.log('Page content error boundary reset');
            }}
        >
            {children}
        </ErrorBoundary>
    );
}

function FallbackPage(props: FallbackProps) {
    return (
        <PageErrorBoundaryLayout
            hiddenHeader
            hiddenFooter
            isAuthError={props.error instanceof NotAuthenticatedError}
        />
    );
}
