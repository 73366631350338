import { useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { tss } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import { ProfileIcon } from '../icons';
import Button from '../Button';
import StyledPopper from '../StyledPopper';
import PortfolioBreadcrumbs from '../PortfolioBreadcrumbs/PortfolioBreadcrumbs';
import { ProfilePopper } from '../ProfilePopper';
import ToggleArrowsUpAndDown from '../ToggleArrowsUpAndDown';
import type { TopBar_pqiUser$key } from './__generated__/TopBar_pqiUser.graphql';
import type { TopBar_team$key } from './__generated__/TopBar_team.graphql';
import type { TopBar_project$key } from './__generated__/TopBar_project.graphql';

const useTopBarStyles = tss.create(({ theme }) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0.25, 1),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0.5, 2),
        },
        minHeight: 64,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0 1px ${theme.bino.color.boxShadowBorder}`,
    },
}));

export type TopBarProps = {
    pqiUser: TopBar_pqiUser$key;
    team: TopBar_team$key;
    project?: TopBar_project$key;
};

export function TopBar(props: TopBarProps) {
    const team = useFragment(fragments.team, props.team);
    const project = useFragment(fragments.project, props.project);
    const pqiUser = useFragment(fragments.pqiUser, props.pqiUser);
    const [showProfilePopper, setShowProfilePopper] = useState(false);
    const { classes } = useTopBarStyles();
    const profileButtonRef = useRef(null);

    return (
        <div className={classes.root}>
            <Box flexGrow={1} pr={3}>
                <PortfolioBreadcrumbs
                    pqiUser={pqiUser}
                    team={team}
                    project={project}
                />
            </Box>
            <Box sx={{ ml: 'auto' }}>
                {pqiUser?.authUser ? (
                    <IconButton
                        sx={{ py: 2, mr: -1 }}
                        onClick={() => setShowProfilePopper(!showProfilePopper)}
                        ref={profileButtonRef}
                        size="large"
                    >
                        <ProfileIcon size={24} />
                        <ToggleArrowsUpAndDown
                            toggle={showProfilePopper}
                            size={12}
                        />
                    </IconButton>
                ) : (
                    <Button
                        fullWidth
                        color="secondary"
                        to={'/login'}
                        component={Link}
                    >
                        <FormattedMessage defaultMessage="Log in" />
                    </Button>
                )}
            </Box>
            <StyledPopper
                open={showProfilePopper}
                onClose={() => setShowProfilePopper(false)}
                anchorEl={profileButtonRef.current}
            >
                <ProfilePopper
                    user={pqiUser?.authUser}
                    close={() => setShowProfilePopper(false)}
                />
            </StyledPopper>
        </div>
    );
}

const fragments = {
    pqiUser: graphql`
        fragment TopBar_pqiUser on PQiUser {
            authUser {
                ...ProfilePopper_user
            }
            ...PortfolioBreadcrumbs_pqiUser
        }
    `,
    team: graphql`
        fragment TopBar_team on ProjectMeasuring_Team {
            path
            ...PortfolioBreadcrumbs_team
        }
    `,
    project: graphql`
        fragment TopBar_project on ProjectMeasuring_Project {
            ...PortfolioBreadcrumbs_project
        }
    `,
};
