import RootModal from '@mui/material/Modal';
import { Box } from '@mui/material';

type ZoomedImgProps = {
    open: boolean;
    onClose: () => void;
    alt: string;
    img: {
        height: number;
        width: number;
        base64: string;
        src: string;
    };
};

export default function ZoomedImg({ open, onClose, alt, img }: ZoomedImgProps) {
    const closeTheModalWhenClickingOutsideTheContent = onClose;

    const modalContent = (
        <Box
            sx={{
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: 'none',
                cursor: 'zoom-out',
                maxWidth: '100%',
                maxHeight: '100%',
                // backgroundImage: `url(${img.base64})`,
                height: img.height,
                width: img.width,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                textAlign: 'center',
                '& img': {
                    maxWidth: '100%',
                    maxHeight: '100%',
                },
            }}
        >
            {<img src={img.src} alt={alt} loading="lazy" />}
        </Box>
    );

    return (
        <RootModal
            open={open}
            onClick={onClose}
            onClose={closeTheModalWhenClickingOutsideTheContent}
            style={{
                cursor: 'zoom-out',
            }}
        >
            {modalContent}
        </RootModal>
    );
}
