import * as React from 'react';
import type { SnackbarOptions } from './components/SnackbarProvider';

interface SnackbarContextType {
    enqueueSnackbar(message: React.ReactNode, options?: SnackbarOptions): void;
}
export const SnackbarContext = React.createContext<SnackbarContextType | null>(
    null,
);

export default SnackbarContext;
