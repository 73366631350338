import type { ReactNode } from 'react';
import { Suspense } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { Box, Stack } from '@mui/material';
import { useIsScreenSmallerThanBreakpoint } from '@/muiTheme';
import {
    HelpCenterRelatedArticlesSidebar,
    HelpCenterRelayEnvironment,
} from '@/features/help-center';
import ErrorBoundary from '../ErrorBoundary';
import { LoadingIndicator } from '../LoadingIndicator';
import type { CollapsedBarMode } from '../CollapsibleBars';
import {
    collapsibleBarClasses,
    CollapsibleBarRight,
    CollapsibleBars,
    TriggerButtons,
} from '../CollapsibleBars';
import { collapsedSize, sidebarWidth } from './constants.ts';
import { PageCollapsibleSidebar } from './PageCollapsibleSidebar.tsx';
import { PageContentErrorBoundary } from './PageContentErrorBoundary';
import { PageFooter } from './PageFooter';
import { TopBarWithLogo } from './TopBarWithLogo.tsx';

export type LayoutNewProps = {
    children?: ReactNode;
    footer?: ReactNode;
    disableFooter?: boolean;
    renderNavigation?: (
        isMobile: boolean,
        collapsedMode: CollapsedBarMode,
    ) => ReactNode;
    helpCenterRelatedArticlesPageId?: string;
    helpCenterCollapsedWidth?: number;
    helpCenterWidth?: number;
    topBar?: ReactNode;
};

/**
 * <Layout /> handles the "positioning" and its "visibility" of the sidebar, main content, and footer.
 * It holds their respective styles and their structures.
 * Those elements, e.g. sidebar, are the only elements hold by it.
 * And also, those elements are passed from user-land.
 *
 * Remember, <Layout /> would only handle the layout/position styles. If the Components
 * like <Footer /> will have its appearance styles, this must be set to the <Footer /> not on this
 * for better collocation and will only stick to the <Layout /> responsibility.
 * Though there are default dimension styles provided by the <Layout /> but this is ok
 * as long that appearance like `background-color` is not defined in here.
 *
 * The great reason for this is to have the user capability about what's
 * the content of those elements to have better composeability instead
 * of defining directly in here. This will open our <Layout /> to compose
 * layout for the current pages, e.g. dashboard, and CPS.
 *
 * <Layout /> also has 2 different layout depending on the viewport "mobile" and "desktop".
 * This is distinguished by the `isMobile` prop.
 * */
export const PageLayout = ({
    children,
    footer,
    disableFooter,
    renderNavigation,
    helpCenterRelatedArticlesPageId,
    topBar,
    helpCenterWidth = sidebarWidth,
    helpCenterCollapsedWidth = 16,
}: LayoutNewProps) => {
    const isSmallScreen = useIsScreenSmallerThanBreakpoint('md');

    // md is defined as 900px, and might not really be "mobile" but the sidebar gets too small, and a
    // collapsed sidebar is not very useful on mobile devices where you cannot hover to see the content.
    // We should really do a hamburger menu, like we did in the CPS
    const isMobile = useIsScreenSmallerThanBreakpoint('md');

    // The isMobile and collapseMode props really have no effect. We should really refactor this and
    // separate the collapsible sidebar navigation and the mobile navigation bar.
    // todo Add a separate mobileNavigationBar prop (ReactNode) and change renderNavigation to renderSidebar
    // todo Evaluate if we should DROP the mobile navigation bar and replace with a hamburger menu (like we
    //      do in the CPS)
    const mobileNavigation = isMobile && renderNavigation?.(true, 'collapse');
    const navigationSidebar = !isMobile && renderNavigation && (
        <PageCollapsibleSidebar hideLogo={false} isMobile={false}>
            {({ mode }) => renderNavigation(false, mode)}
        </PageCollapsibleSidebar>
    );
    const helpCenterSidebar = !isMobile && helpCenterRelatedArticlesPageId && (
        <CollapsibleBarRight
            sticky
            renderButton={(props) => <TriggerButtons.RightButton {...props} />}
            sx={(theme) => ({
                pt: '1px',
                [`& .${collapsibleBarClasses.innerCollapsible}`]: {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    boxShadow: `-1px 0 0 0 ${theme.bino.color.boxShadowBorder}`,
                },
                [`& .${collapsibleBarClasses.innerContent}`]: {
                    padding: '1em',
                    boxSizing: 'border-box',
                },
            })}
        >
            <RelayEnvironmentProvider environment={HelpCenterRelayEnvironment}>
                <ErrorBoundary>
                    <HelpCenterRelatedArticlesSidebar
                        pageId={helpCenterRelatedArticlesPageId}
                    />
                </ErrorBoundary>
            </RelayEnvironmentProvider>
        </CollapsibleBarRight>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth: '100vw',
                minHeight: `100vh`,
                flexDirection: 'column',
            }}
        >
            <CollapsibleBars
                sidebars={{
                    left: {
                        disabled: isMobile || !renderNavigation,
                        width: sidebarWidth,
                        collapsedWidth: collapsedSize,
                        //initiallyCollapsed: isSmallScreen,
                        element: navigationSidebar,
                    },
                }}
            >
                <Stack height="100%">
                    <TopBarWithLogo
                        variant={
                            isMobile
                                ? 'symbol'
                                : renderNavigation
                                  ? 'none'
                                  : 'logo'
                        }
                        symbolContainerWidth={collapsedSize}
                        logoContainerWidth={sidebarWidth}
                    >
                        {topBar}
                    </TopBarWithLogo>
                    {mobileNavigation}
                    {helpCenterSidebar ? (
                        <CollapsibleBars
                            sidebars={{
                                right: {
                                    width: helpCenterWidth,
                                    collapsedWidth: helpCenterCollapsedWidth,
                                    element: helpCenterSidebar,
                                    initiallyCollapsed: isSmallScreen,
                                },
                            }}
                        >
                            <LayoutNewChildren>{children}</LayoutNewChildren>
                        </CollapsibleBars>
                    ) : (
                        <LayoutNewChildren>{children}</LayoutNewChildren>
                    )}
                </Stack>
            </CollapsibleBars>
            {disableFooter ? null : footer ?? <PageFooter />}
        </Box>
    );
};

// Not sure this has an effect really
const LayoutNewChildren = ({ children }: { children?: ReactNode }) => (
    <Suspense fallback={<LoadingIndicator centered />}>
        <PageContentErrorBoundary>
            <Box sx={{ p: [0.5, 1, 2], flexGrow: 1 }} component="main">
                {children}
            </Box>
        </PageContentErrorBoundary>
    </Suspense>
);
