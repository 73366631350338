import type { Environment } from 'react-relay';
import { graphql, loadQuery } from 'react-relay';
import type { ProjectPageQuery$variables } from './__generated__/ProjectPageQuery.graphql.ts';
export type * from './__generated__/ProjectPageQuery.graphql.ts';

export const loadProjectPageQuery = (
    environment: Environment,
    variables: ProjectPageQuery$variables,
) => {
    return loadQuery(environment, projectPageQuery, variables);
};

export const projectPageQuery = graphql`
    query ProjectPageQuery(
        $projectPath: String!
        $teamPath: String!
        $portfolioPath: String!
    ) {
        team: projectMeasuring_teamByPath(path: $teamPath) {
            portfolio(path: $portfolioPath) {
                project(path: $projectPath) {
                    id
                    ...ProjectSidebar_project
                    ...TopBar_project
                }
            }
            ...TopBar_team
        }

        pqiUser {
            ...TopBar_pqiUser
            ...ProjectSidebar_pqiUser
        }
    }
`;
