/**
 * @generated SignedSource<<6b7a9e5a284c39d53eb1f665bf8b971b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleContent_articleContent$data = {
  readonly blocks: ReadonlyArray<{
    readonly __typename: string;
    readonly id?: string;
    readonly video?: {
      readonly height: number | null | undefined;
      readonly provider: string | null | undefined;
      readonly providerUid: string | null | undefined;
      readonly title: string | null | undefined;
      readonly url: string | null | undefined;
      readonly width: number | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ArticleLinkRecord_content" | "HeadingRecord_content" | "ImageRecord_content" | "PreambleRecord_content" | "TextRecord_content">;
  }>;
  readonly links: ReadonlyArray<{
    readonly __typename: "HelpCenterArticleRecord";
    readonly id: string;
    readonly title: string | null | undefined;
    readonly topic: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly url: string | null | undefined;
  }>;
  readonly value: any;
  readonly " $fragmentType": "ArticleContent_articleContent";
};
export type ArticleContent_articleContent$key = {
  readonly " $data"?: ArticleContent_articleContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleContent_articleContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleContent_articleContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpCenterArticleRecord",
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpCenterTopicRecord",
          "kind": "LinkedField",
          "name": "topic",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeadingRecord_content"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArticleLinkRecord_content"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ImageRecord_content"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PreambleRecord_content"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TextRecord_content"
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "HeadingRecord",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "ArticleLinkRecord",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "ImageRecord",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "PreambleRecord",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoField",
              "kind": "LinkedField",
              "name": "video",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "provider",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "providerUid",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "VideoRecord",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "TextRecord",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HelpCenterArticleModelArticleContentField",
  "abstractKey": null
};
})();

(node as any).hash = "2ef1479d1b1e1ae4458fa492409dec88";

export default node;
