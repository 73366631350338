import { Route } from 'react-router-dom';

export default (
    <>
        <Route
            path="*"
            lazy={() =>
                import('./index.tsx').then((m) => ({
                    Component: m.default,
                }))
            }
        />
    </>
);
