/**
 * @generated SignedSource<<d9f267a86a17b0f254998796f33253d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteLocale = "en_GB" | "sv_SE" | "%future added value";
export type HelpCenterStartPageQuery$variables = {
  first?: number | null | undefined;
  locale: SiteLocale;
};
export type HelpCenterStartPageQuery$data = {
  readonly articles: ReadonlyArray<{
    readonly id: string;
    readonly title: string | null | undefined;
    readonly topic: {
      readonly id: string;
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly url: string | null | undefined;
  }>;
  readonly topics: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null | undefined;
    readonly url: string | null | undefined;
  }>;
};
export type HelpCenterStartPageQuery = {
  response: HelpCenterStartPageQuery$data;
  variables: HelpCenterStartPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v2 = {
  "kind": "Variable",
  "name": "locale",
  "variableName": "locale"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = [
  {
    "alias": "articles",
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      (v2/*: any*/)
    ],
    "concreteType": "HelpCenterArticleRecord",
    "kind": "LinkedField",
    "name": "allHelpCenterArticles",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "HelpCenterTopicRecord",
        "kind": "LinkedField",
        "name": "topic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "topics",
    "args": [
      (v2/*: any*/)
    ],
    "concreteType": "HelpCenterTopicRecord",
    "kind": "LinkedField",
    "name": "allHelpCenterTopics",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HelpCenterStartPageQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HelpCenterStartPageQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5db32d425e52d227f3200b27e4de5f1b",
    "id": null,
    "metadata": {},
    "name": "HelpCenterStartPageQuery",
    "operationKind": "query",
    "text": "query HelpCenterStartPageQuery(\n  $locale: SiteLocale!\n  $first: IntType\n) {\n  articles: allHelpCenterArticles(locale: $locale, first: $first) {\n    id\n    title\n    url\n    topic {\n      id\n      url\n    }\n  }\n  topics: allHelpCenterTopics(locale: $locale) {\n    id\n    name\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "3370f45cd363356180a701c4a211b6cb";

export default node;
