import type { ReactNode, JSX } from 'react';
import { Link } from 'react-router-dom';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import type { Breadcrumb_topic$key } from './__generated__/Breadcrumb_topic.graphql';

const fragments = {
    topic: graphql`
        fragment Breadcrumb_topic on HelpCenterTopicRecord {
            id
            name
            url
        }
    `,
};

export default function Breadcrumb(props: {
    helpCenterUrl: string;
    topic?: Breadcrumb_topic$key;
}) {
    const { helpCenterUrl } = props;
    const topic = useFragment(fragments.topic, props.topic);

    // Breadcrumb has maximum of 2 levels, the help center root, and the topic.
    // We hardcoded the structure of the breadcrumb based on their relationship.
    // Initial content is the help center because this is the root path.
    const contents: { label: ReactNode; to: string }[] = [
        {
            label: <FormattedMessage defaultMessage="Help Center" />,
            to: helpCenterUrl,
        },
    ];

    // If there is topic.
    if (topic) {
        contents.push({
            label: topic.name,
            to: `${helpCenterUrl}/${topic.url}`,
        });
    }

    const breadcrumbContent = contents.map(({ to, label }, idx, arr) => {
        const key = `${label}-${idx}`;

        // if last path, then we make the element to just text not link and use the topic.name
        if (idx === arr.length - 1) {
            return (
                <li key={key}>
                    <span>{label}</span>
                </li>
            );
        }

        return (
            <li key={key}>
                <Link to={to}>{label}</Link>
                <Separator />
            </li>
        );
    });

    return (
        <Nav aria-label="Help center breadcrumb">
            <Ol>{breadcrumbContent}</Ol>
        </Nav>
    );
}

const Nav = styled('nav')(({ theme }) => ({
    margin: '16px',

    [theme.breakpoints.up('md')]: {
        margin: '8px 0 24px 0',
    },
}));

const Ol = styled('ol')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    listStyleType: 'none',
    padding: 0,
    margin: 0,

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
    '& > li': {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '1px',
        fontWeight: 'bold',
    },
    '& > li > span': {
        color: theme.bino.color.darkGray,
        textTransform: 'uppercase',
    },
    '& > li > a': {
        textTransform: 'uppercase',
    },
}));
const Separator = styled((props: JSX.IntrinsicElements['span']) => (
    <span {...props}>/</span>
))(({ theme }) => ({
    margin: 4,
    display: 'inline-block',

    [theme.breakpoints.up('sm')]: {
        margin: '0 4px',
    },
}));
