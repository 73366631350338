import { useTheme, useMediaQuery, styled } from '@mui/material';
import { useState } from 'react';
import type { JSX } from 'react';
import { useFragment, graphql } from 'react-relay';
import zoomInButtonImg from './zoom-in-button.png';
import ZoomedImg from './ZoomedImg';
import type { ImageRecord_content$key } from './__generated__/ImageRecord_content.graphql';

export default function ImageRecord(props: {
    content: ImageRecord_content$key;
}) {
    const [isOpen, setIsOpen] = useState(false);
    const content = useFragment(contentFragment, props.content);
    const { image, caption } = content;

    // Change the src depending on the currrent breakpoint. The cms has an option to resize the original image
    // based on the provided constraint width. We used the option to render different images, based on the size, in different breakpoints.
    const { lgImg, mdImg, smImg } = image;
    const theme = useTheme();
    // put this logic and queriying of data inside the zoomed img?
    // TODO: We need to create a "Low quality image placeholders" for the zoomed image. Need to make sure that the
    // placeholder supports different breakpoints. Im not sure if we need to use srcset because we already have js logic? let see!
    const useMediaQueryOptions = {
        // https://github.com/mui-org/material-ui/issues/21048
        noSsr: true,
    };
    const isXsViewport = useMediaQuery(
        theme.breakpoints.only('xs'),
        useMediaQueryOptions,
    );
    const isSmViewport = useMediaQuery(
        theme.breakpoints.only('sm'),
        useMediaQueryOptions,
    );
    const isMdViewport = useMediaQuery(
        theme.breakpoints.only('md'),
        useMediaQueryOptions,
    );

    let zoomedImg: typeof smImg;
    if (isXsViewport || isSmViewport) {
        zoomedImg = smImg;
    } else if (isMdViewport) {
        zoomedImg = mdImg;
    } else {
        zoomedImg = lgImg;
    }

    return (
        <Figure>
            <img
                onClick={() => setIsOpen(true)}
                id={image.id}
                src={image.url}
                title={image.title}
                aria-labelledby="image-caption"
                alt={image.alt}
                loading="lazy"
            />
            <figcaption>
                <LensButton onClick={() => setIsOpen(true)} />
                <ZoomedImg
                    alt={caption}
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    img={zoomedImg}
                />
                <span id="image-caption">
                    <i>{caption}</i>
                </span>
            </figcaption>
        </Figure>
    );
}

const contentFragment = graphql`
    fragment ImageRecord_content on ImageRecord
    @argumentDefinitions(
        lgImgWidth: {
            type: "FloatType"
            provider: "ImageRecord_lgImageSize_relayprovider"
        }
        mdImgWidth: {
            type: "FloatType"
            provider: "ImageRecord_mdImageSize_relayprovider"
        }
        smImgWidth: {
            type: "FloatType"
            provider: "ImageRecord_smImageSize_relayprovider"
        }
    ) {
        id
        caption
        image {
            id
            url
            title
            alt

            lgImg: responsiveImage(imgixParams: { w: $lgImgWidth }) {
                src
                base64
                height
                width
            }
            mdImg: responsiveImage(imgixParams: { w: $mdImgWidth }) {
                src
                base64
                height
                width
            }
            smImg: responsiveImage(imgixParams: { w: $smImgWidth }) {
                src
                base64
                height
                width
            }
        }
    }
`;

const LensButton = styled((props: JSX.IntrinsicElements['button']) => (
    <button {...props}>
        <img src={zoomInButtonImg} alt="Zoom in" />
    </button>
))(({ theme }) => ({
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    height: 22,
    width: 22,
    cursor: 'pointer',
    margin: '13px 0',
    display: 'inline-block',

    [theme.breakpoints.up('sm')]: {
        margin: 0,
    },
    '& > img': {
        height: '100%',
        width: '100%',
    },
}));

const Figure = styled('figure')(({ theme }) => ({
    margin: '13px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    height: 166,

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },

    '& > img': {
        maxWidth: '250px',
        maxHeight: '100%',
        objectFit: 'contain',
        objectPosition: 'left bottom',
        cursor: 'zoom-in',
        display: 'block',
    },
    '& > figcaption': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',

        [theme.breakpoints.up('sm')]: {
            padding: '18px 22px',
            alignItems: 'flex-start',
        },
    },
    '& > figcaption > span': {
        fontSize: 14,
        color: theme.palette.text.secondary,

        [theme.breakpoints.up('sm')]: {
            maxWidth: 180,
        },
    },
}));
