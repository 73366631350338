import { useRouteError } from 'react-router-dom';
import { PageErrorBoundaryLayout } from './components';
import { NotAuthenticatedError } from './RelayEnvironment';

export const AppRootRouteBoundary = () => {
    const error = useRouteError();
    const isAuthError = error instanceof NotAuthenticatedError;
    console.error(error);
    return <PageErrorBoundaryLayout fullHeight isAuthError={isAuthError} />;
};
