import { useIntl } from 'react-intl';
import { SiteLocale } from '@/help.center.schema.graphql';

export function useSiteLocale(): SiteLocale {
    const intl = useIntl();
    if (intl.locale === 'sv') {
        return SiteLocale.sv_SE;
    }
    return SiteLocale.en_GB;
}
