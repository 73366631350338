import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import { ArrowLeftIcon } from '@/components';
import { goBackMessage } from '@/common-messages';

export type SubPageLayoutProps = {
    backLinkProps?: Partial<LinkProps>;
};
export const SubPageLayout = (props: SubPageLayoutProps) => {
    const { formatMessage } = useIntl();
    const { children, ...backLinkProps } = props.backLinkProps ?? {};
    return (
        <>
            <Button component={Link} to={''} {...backLinkProps} color="gray">
                <ArrowLeftIcon size={12} sx={{ mr: 1 }} />{' '}
                {children ?? formatMessage(goBackMessage)}
            </Button>
            <Outlet />
        </>
    );
};
