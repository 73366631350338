import { QueryRenderer, graphql } from 'react-relay';
import { styled } from '@mui/material';
import { LoadingIndicator } from '@/components';
import { HelpCenterRelayEnvironment } from '../HelpCenterRelayEnvironment';
import { useSiteLocale } from '../hooks/useSiteLocale';
import { Article } from './Article';
import TopicTableOfContents from './TopicTableOfContents';
import type { TopicArticlesQuery } from './__generated__/TopicArticlesQuery.graphql';

export default function TopicArticles({ topicId }: { topicId: string }) {
    const variables = {
        topicId,
        locale: useSiteLocale(),
    };
    return (
        <QueryRenderer<TopicArticlesQuery>
            environment={HelpCenterRelayEnvironment}
            variables={variables}
            query={query}
            render={({ error, props }) => {
                if (error) {
                    return <div>{error.message}</div>;
                } else if (props) {
                    return (
                        <>
                            <TopicTableOfContents articles={props.articles} />
                            <ArticleList>
                                {props.articles.map((article) => (
                                    <Article
                                        key={article.id as string}
                                        article={article}
                                    />
                                ))}
                            </ArticleList>
                        </>
                    );
                }
                return <LoadingIndicator centered size="large" />;
            }}
        />
    );
}

const query = graphql`
    query TopicArticlesQuery($topicId: ItemId, $locale: SiteLocale) {
        articles: allHelpCenterArticles(
            locale: $locale
            filter: { topic: { eq: $topicId } }
        ) {
            id
            ...Article_article
            ...TopicTableOfContents_articles
        }
    }
`;

const ArticleList = styled('section')(({ theme }) => ({
    display: 'grid',
    rowGap: '32px',

    [theme.breakpoints.up('sm')]: {
        rowGap: '32px',
    },
}));
