import { Navigate, Outlet, Route } from 'react-router-dom';
import { loadQuery } from 'react-relay';
import oldProjectPageRoutes from '@/pages/OldProjectPage/routes.tsx';
import projectPageRoutes from '@/pages/Project/routes.tsx';
import dashboardPageRoutes from '@/pages/Dashboard/routes.tsx';
import { atomsStorage } from '@/global-states';
import {
    cpsIntermediatePageUrlAnchor,
    cpsPageUrlAnchor,
} from '@/pages/CPS/constants.ts';
import { getLastPortfolioPath, projectPathParameterName } from './RootPaths.ts';
import { AppRootRouteBoundary } from './AppRootRouteBoundary.tsx';
import { TeamPageLayoutRoute } from './routes/TeamPageLayoutRoute.tsx';
import type { TeamPageLayoutRouteQuery } from './routes/TeamPageLayoutRouteQuery.ts';
import { teamPageLayoutRouteQuery } from './routes/TeamPageLayoutRouteQuery.ts';
import { getRelayEnvironment } from './RelayEnvironment';
import { TeamRoutePath } from './routes/TeamRoutePath.ts';
import { projectsPageQuery } from './pages/Projects/ProjectsPage.query.ts';
import { onlyMyProjectsState } from './utils/useOnlyMyProjects.ts';
import { AppSuspense } from './AppSuspense.tsx';

const mapDefaultToComponent = <C = unknown,>(m: { default: C }) => ({
    Component: m.default,
});

export default (
    <Route
        ErrorBoundary={AppRootRouteBoundary}
        element={
            <AppSuspense>
                <Outlet />
            </AppSuspense>
        }
    >
        <Route
            /* todo OauthTokenCallback is not in use, we should likely remove. Investigate */
            path="/oauth/token-callback"
            lazy={() =>
                import('@/pages/OauthTokenCallback').then(mapDefaultToComponent)
            }
        />
        <Route
            /* todo MagicLinkHandler is not in use, we should likely remove. Investigate */
            path="/_/:token/*"
            lazy={() =>
                import('@/MagicLinkHandler').then(mapDefaultToComponent)
            }
        />
        <Route
            path="/login/*"
            lazy={() => import('@/pages/LoginPage').then(mapDefaultToComponent)}
        />
        <Route
            path="/old-login"
            lazy={() =>
                import('@/pages/LoginPage/SinglePageLogin').then(
                    mapDefaultToComponent,
                )
            }
        />
        <Route
            path="/about"
            lazy={() => import('@/pages/About').then(mapDefaultToComponent)}
        />
        <Route
            path="/help-center/*"
            lazy={() =>
                import('@/pages/HelpCenterNoTeamPage').then(
                    mapDefaultToComponent,
                )
            }
        />
        <Route
            path="/profile"
            lazy={() => import('@/pages/Profile').then(mapDefaultToComponent)}
        />
        <Route
            path="/register-team"
            lazy={() =>
                import('./pages/TeamSelfRegistration').then(
                    mapDefaultToComponent,
                )
            }
        />
        <Route
            path="/"
            lazy={() =>
                import('./pages/TeamSelect').then(mapDefaultToComponent)
            }
        />
        <Route path="/:teamPath">
            <Route
                /*We need access to the old antique legacy project for a while under the /old path */
                path={`projects/:${projectPathParameterName}/old/*`}
            >
                {oldProjectPageRoutes}
            </Route>

            <Route
                path={`profile`}
                lazy={() =>
                    import('@/pages/Profile').then(mapDefaultToComponent)
                }
            />
            <Route
                path={`${cpsPageUrlAnchor}/*`}
                lazy={() =>
                    import('@/pages/CPS/CPSPage.tsx').then(
                        mapDefaultToComponent,
                    )
                }
            />
            <Route
                path={`projects/:${projectPathParameterName}/${cpsPageUrlAnchor}/*`}
                lazy={() =>
                    import('@/pages/CPS/CPSPage.tsx').then(
                        mapDefaultToComponent,
                    )
                }
            />
            {/* we want to have intermediate pages as separate route
             * because don't want to add stuff like top bar, left bar
             * and so on
             *
             * todo See if we can remove this from this file at least
             */}
            <Route
                path={`projects/:${projectPathParameterName}/${cpsIntermediatePageUrlAnchor}/*`}
                lazy={() =>
                    import(
                        '@/pages/CPS/intermediate-screen/IntermediatePageRoutes.tsx'
                    ).then(mapDefaultToComponent)
                }
            />
            <Route
                path={`projects/:${projectPathParameterName}/results/:surveyNumber/*`}
                lazy={() =>
                    import('@/pages/ProjectSurveyResults').then(
                        mapDefaultToComponent,
                    )
                }
            />
            <Route path={`projects/:${projectPathParameterName}`}>
                {projectPageRoutes}
            </Route>
            <Route
                Component={TeamPageLayoutRoute}
                loader={({ params }) => ({
                    queryRef: loadQuery<TeamPageLayoutRouteQuery>(
                        getRelayEnvironment(),
                        teamPageLayoutRouteQuery,
                        {
                            teamPath: params.teamPath,
                        },
                    ),
                })}
            >
                <Route
                    index
                    element={
                        <Navigate to={`${TeamRoutePath.PROJECTS}`} replace />
                    }
                />
                <Route
                    path={`${TeamRoutePath.DASHBOARD}/*`}
                    // handle={{
                    //     lazyChildren: () => import('@/pages/Dashboard/routes.tsx'),
                    // }}
                >
                    {dashboardPageRoutes}
                </Route>
                <Route
                    path={`${TeamRoutePath.PROJECTS}/*`}
                    lazy={() =>
                        import('@/pages/Projects/index.tsx').then(
                            mapDefaultToComponent,
                        )
                    }
                    loader={({ params }) => ({
                        queryRef: loadQuery(
                            getRelayEnvironment(),
                            projectsPageQuery,
                            {
                                teamPath: params.teamPath,
                                portfolioPath: getLastPortfolioPath(params),
                                // Not sure if this is the best way. Hopefully there will be a way to use hooks or
                                // context in a future release, or we put this information in the URL.
                                onlyMyProjects:
                                    atomsStorage.getItem(
                                        onlyMyProjectsState.key,
                                    ) === true,
                            },
                        ),
                    })}
                />
                <Route
                    path={`${TeamRoutePath.REPORTS}/*`}
                    lazy={() =>
                        import('@/pages/Reports').then(mapDefaultToComponent)
                    }
                />
                <Route
                    path={`${TeamRoutePath.COMPARE}/*`}
                    lazy={() =>
                        import('@/pages/Compare').then(mapDefaultToComponent)
                    }
                />
                <Route
                    path={`${TeamRoutePath.IMPROVEMENT_BANK}/*`}
                    lazy={() =>
                        import('@/pages/ImprovementBank').then(
                            mapDefaultToComponent,
                        )
                    }
                />

                <Route path={`${TeamRoutePath.SETTINGS}/*`}>
                    <Route
                        path="*"
                        lazy={() =>
                            import('@/pages/TeamSettings').then(
                                mapDefaultToComponent,
                            )
                        }
                    />
                    <Route
                        path="team-questions/:questionNumber/*"
                        lazy={() =>
                            import(
                                '@/pages/TeamSettings/AddQuestionToTeamPage.tsx'
                            ).then(mapDefaultToComponent)
                        }
                    />
                </Route>
                <Route
                    path={`${TeamRoutePath.HELP_CENTER}/*`}
                    lazy={() =>
                        import('@/features/help-center').then((m) => ({
                            Component: m.HelpCenter,
                        }))
                    }
                />
                <Route
                    path="*"
                    lazy={() =>
                        import('@/pages/TeamRouteNotFoundPage.tsx').then(
                            (m) => ({
                                Component: m.TeamRouteNotFoundPage,
                            }),
                        )
                    }
                />
            </Route>
        </Route>
    </Route>
);
