import { Typography, Grid, Skeleton, styled } from '@mui/material';
import { tss } from 'tss-react/mui';
import {
    getThemeColor,
    useIsScreenSmallerThanBreakpoint,
} from '../../muiTheme.ts';
import { Paper } from '../../components';

export default function ProjectSurveysPageSkeleton() {
    const { classes, cx } = useSurveysResultsSkeletonStyles();
    const isMobile = useIsScreenSmallerThanBreakpoint();
    return (
        <div>
            {Array.from({ length: 2 }).map((_, idx) => (
                <Survey key={idx}>
                    <header
                        className={cx(
                            classes.surveyContainer,
                            classes.surveyHeader,
                        )}
                    >
                        <Typography component="div" variant="h2">
                            <Skeleton width={120} />
                        </Typography>
                        <div className={classes.surveyHeaderActionsContainer}>
                            <Typography component="div" variant="subtitle1">
                                <Skeleton width={60} />
                            </Typography>
                            <Typography component="div" variant="subtitle1">
                                <Skeleton width={40} />
                            </Typography>
                        </div>
                    </header>
                    <div className={classes.horizontalLine} />
                    <div
                        className={cx(
                            classes.surveyContainer,
                            classes.surveyContent,
                        )}
                    >
                        <Grid container direction="column" spacing={2}>
                            {Array.from({ length: isMobile ? 1 : 2 }).map(
                                (_, idx) => (
                                    <Grid item container spacing={3} key={idx}>
                                        <Grid item>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                            >
                                                <Skeleton width={100} />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                component="div"
                                                variant="subtitle1"
                                            >
                                                <Skeleton width={100} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ),
                            )}
                        </Grid>
                        <div className={classes.surveyContentActionsContainer}>
                            <Typography component="div" variant="subtitle1">
                                <Skeleton width={60} />
                            </Typography>
                            <Typography component="div" variant="subtitle1">
                                <Skeleton width={40} />
                            </Typography>
                        </div>
                    </div>
                </Survey>
            ))}
        </div>
    );
}

const useSurveysResultsSkeletonStyles = tss.create(({ theme }) => ({
    horizontalLine: {
        backgroundColor: getThemeColor(theme)('boxShadowBorder'),
        height: 1,
    },
    surveyContainer: {
        padding: '24px 40px',
    },
    surveyHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    surveyHeaderActionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    surveyContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    surveyContentActionsContainer: {
        display: 'flex',
        '& > *': {
            marginLeft: 8,
        },
    },
}));

const Survey = styled(Paper, { name: 'Survey' })(({ theme }) => ({
    marginBottom: '2em',
    padding: 0,

    '&:last-child': {
        marginBottom: 0,
    },

    [theme.breakpoints.down('md')]: {
        margin: '16px 16px 0',
        '&:last-child': {
            marginBottom: 0,
        },
    },
}));
