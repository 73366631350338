import * as React from 'react';
import { Link } from 'react-router-dom';
import type { Theme } from '@mui/material';
import { styled, useTheme } from '@mui/material';
import type { CollapsedBarMode } from '../CollapsibleBars';
import {
    collapsibleBarClasses,
    CollapsibleBarLeft,
    TriggerButtons,
    useCollapsibleBarsContext,
} from '../CollapsibleBars';
import ErrorBoundary from '../ErrorBoundary';
import { SymbolIcon } from '../icons';
import Logo from '../Logo';
import { createSmoothTransitionStyles } from '../CollapsibleBars/utils';

const minimizedSidebar = {
    sidebarWidth: 64,
    symbolSize: 32,
};
const CollapsibleBarLeftStyled = styled(CollapsibleBarLeft)(({ theme }) => ({
    [`& .${collapsibleBarClasses.innerCollapsible}`]: {
        background: theme.palette.background.paper,
        borderRight: `1px solid ${theme.bino.color.boxShadowBorder}`,
    },
}));

const LogoContainer = styled('div', {
    shouldForwardProp: (propName) => !['mode'].includes(propName as string),
})<{ mode: CollapsedBarMode }>(({ theme, mode }) => ({
    backgroundColor: theme.bino.color.primary,
    padding: '8px 0',
    boxSizing: 'border-box',
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    boxShadow: `0 1px 0 0 ${theme.bino.color.boxShadowBorder}`,
    ...createSmoothTransitionStyles('width'),
    [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
        padding: '8px 0',
        height: 64,
        boxShadow: `0 1px 0 0 ${theme.bino.color.boxShadowBorder}`,
        ...(mode === 'collapse' && {
            padding:
                (minimizedSidebar.sidebarWidth - minimizedSidebar.symbolSize) /
                2,
            '& svg': {
                color: theme.bino.color.white,
            },
        }),
    },
}));

export type PageCollapsibleSidebarProps = {
    isMobile: boolean;
    visible?: boolean;
    hideLogo?: boolean;
    disabledCollapsed?: boolean;
    children: (props: { mode: CollapsedBarMode }) => React.ReactNode;
};

export const PageCollapsibleSidebar: React.FunctionComponent<
    PageCollapsibleSidebarProps
> = ({ isMobile, hideLogo, disabledCollapsed = false, children }) => {
    // We need to calculate the sticky top position to make it scroll to the bottom of the sidebar.
    const contentRef = React.useRef<HTMLDivElement>();
    const config = useCollapsibleBarsContext()['left'];
    const sidebarWidth = config.width;
    const sidebarCollapsedWidth = config.collapsedWidth;

    const [stickyTop, setStickyTop] = React.useState<number>(0);
    const calculateStickyTop = React.useCallback(() => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.offsetHeight;
            const windowHeight = window.innerHeight;
            const newStickyTop = Math.min(windowHeight - contentHeight, 0);
            setStickyTop(newStickyTop);
        }
    }, [contentRef]);
    const theme = useTheme<Theme>();
    const createLogoContainerStyle = (mode: CollapsedBarMode) =>
        !isMobile // For desktop viewport
            ? {
                  width:
                      mode === 'collapse'
                          ? sidebarCollapsedWidth
                          : sidebarWidth,
              } // Mobile viewport
            : { width: 'inherit' };

    React.useEffect(
        function changeStickyAfterContentResize() {
            window.addEventListener('resize', calculateStickyTop);
            window.addEventListener('scroll', calculateStickyTop);
            return () => {
                window.removeEventListener('resize', calculateStickyTop);
                window.removeEventListener('scroll', calculateStickyTop);
            };
        },
        [calculateStickyTop],
    );

    return (
        <CollapsibleBarLeftStyled
            sticky={
                false
                /* We use a custom sticky behaviour for the main sidebar */
            }
            renderButton={(props) => {
                return !disabledCollapsed ? (
                    <TriggerButtons.LeftButton {...props} />
                ) : null;
            }}
            render={({ mode }) => (
                <>
                    {!hideLogo && (
                        <LogoContainer
                            mode={mode}
                            style={createLogoContainerStyle(mode)}
                        >
                            <Link to={'/'}>
                                {
                                    /* Use `SymbolIcon` if collapsed, and log if expanded */
                                    mode === 'collapse' ? (
                                        <SymbolIcon
                                            size={minimizedSidebar.symbolSize}
                                            color={theme.bino.color.white}
                                        />
                                    ) : (
                                        <Logo
                                            size={isMobile ? 'sm' : 'md'}
                                            white
                                        />
                                    )
                                }
                            </Link>
                        </LogoContainer>
                    )}
                    <ErrorBoundary>
                        <div
                            style={{
                                position: 'sticky',
                                top: stickyTop,
                            }}
                            ref={contentRef}
                        >
                            {children({ mode })}
                        </div>
                    </ErrorBoundary>
                </>
            )}
        />
    );
};
