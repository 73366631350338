import type { PropsWithChildren, ReactNode } from 'react';
import { Suspense, useRef } from 'react';
import { Box } from '@mui/material';
import { AppProgressBar } from './components';

export type AppSuspenseProps = PropsWithChildren;
export const AppSuspense = ({ children }: AppSuspenseProps) => {
    const previousChildren = useRef<ReactNode>();
    if (children) {
        // Setting children here eventually leads to bugs and crashes. Must be another way
        // which likely requires another route structure, or maybe solvable with react router v6.
        // previousChildren.current = children;
    }

    return (
        <Suspense
            fallback={
                <AppSuspenseFallback>
                    {previousChildren.current}
                </AppSuspenseFallback>
            }
        >
            {children}
        </Suspense>
    );
};

type AppSuspenseFallback = PropsWithChildren;
const AppSuspenseFallback = ({ children }: AppSuspenseFallback) => {
    return (
        <Box>
            <AppProgressBar />
            {children}
        </Box>
    );
};
