import { styled } from '@mui/material';
import { useFragment, graphql } from 'react-relay';
import type { TopicTableOfContents_articles$key } from './__generated__/TopicTableOfContents_articles.graphql';

export type TopicTableOfContentsProps = {
    articles: TopicTableOfContents_articles$key;
};
export const TopicTableOfContents = (props: TopicTableOfContentsProps) => {
    const articles = useFragment(fragments.articles, props.articles);
    return (
        <Nav aria-label="Table of Contents">
            <Ol>
                {articles.map((article) => (
                    <Li key={article.id as string}>
                        <a href={`#${article.url}`}>{article.title}</a>
                    </Li>
                ))}
            </Ol>
        </Nav>
    );
};

const fragments = {
    articles: graphql`
        fragment TopicTableOfContents_articles on HelpCenterArticleRecord
        @relay(plural: true) {
            id
            title
            url
        }
    `,
};

const Nav = styled('nav')(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'light'
            ? theme.bino.color.snow
            : theme.palette.background.paper,
    border: `1px solid ${theme.bino.color.lightGray}`,
    borderRadius: 4,
    padding: 18,

    [theme.breakpoints.up('sm')]: {
        padding: 24,
    },
}));
const Ol = styled('ol')({
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    counterReset: 'topic-article-list',
});
const Li = styled('ol')(({ theme }) => ({
    color: theme.bino.color.primary,
    marginBottom: 13,

    '&:last-child': {
        marginBottom: 0,
    },

    '&::before': {
        content: 'counter(topic-article-list) "."',
        counterIncrement: 'topic-article-list',
        textAlign: 'left',
        paddingRight: 24,

        [theme.breakpoints.up('sm')]: {
            paddingRight: 30,
        },
    },
}));

export default TopicTableOfContents;
