import { styled } from '@mui/material';

const Text = styled('p')(({ theme }) => ({
    fontSize: 16,
    lineHeight: '26px',
    color: theme.palette.text.primary,
    margin: '20px 0 0 0',

    [theme.breakpoints.up('sm')]: {
        margin: '25px 0 0 0',
    },
}));

export default Text;
