/**
 * @generated SignedSource<<5146b772b2f28faff05b8a89e6496f74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectMeasuring_ProjectStatus = "COMPLETED" | "OK" | "PAUSED" | "%future added value";
export type ProjectMeasuring_ProjectSurveyStatus = "ACTIVATED" | "BEHIND_PRELIMINARY_SCHEDULE" | "BEHIND_SCHEDULE" | "COMPLETED" | "ONGOING" | "PRELIMINARY" | "SCHEDULED" | "%future added value";
export type ProjectsPageQuery$variables = {
  onlyMyProjects?: boolean | null | undefined;
  portfolioPath: string;
  teamPath: string;
};
export type ProjectsPageQuery$data = {
  readonly pqiUser: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectsEmptyState_pqiUser">;
  } | null | undefined;
  readonly team: {
    readonly portfolio: {
      readonly projectSurveys: ReadonlyArray<{
        readonly closedAt: string | null | undefined;
        readonly endDate: string | null | undefined;
        readonly projectId: string;
        readonly status: ProjectMeasuring_ProjectSurveyStatus;
        readonly surveyDate: string;
        readonly surveyNumber: number;
      }>;
      readonly projects: ReadonlyArray<{
        readonly createdAt: string;
        readonly id: string;
        readonly improvementBankId: string | null | undefined;
        readonly name: string;
        readonly path: string;
        readonly projectManager: {
          readonly emailAddress: string;
          readonly fullName: string;
          readonly id: string;
        } | null | undefined;
        readonly status: ProjectMeasuring_ProjectStatus;
        readonly surveyCount: number | null | undefined;
      }>;
    } | null | undefined;
    readonly projectCategories: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectsEmptyState_team">;
  } | null | undefined;
};
export type ProjectsPageQuery = {
  response: ProjectsPageQuery$data;
  variables: ProjectsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyMyProjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portfolioPath"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamPath"
},
v3 = [
  {
    "kind": "Variable",
    "name": "path",
    "variableName": "teamPath"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "path",
    "variableName": "portfolioPath"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surveyNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surveyDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "onlyMyProjects",
      "variableName": "onlyMyProjects"
    }
  ],
  "concreteType": "ProjectMeasuring_Project",
  "kind": "LinkedField",
  "name": "projects",
  "plural": true,
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "projectManager",
      "plural": false,
      "selections": [
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surveyCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "improvementBankId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectMeasuring_ProjectCategory",
  "kind": "LinkedField",
  "name": "projectCategories",
  "plural": true,
  "selections": [
    (v12/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PQiUser",
        "kind": "LinkedField",
        "name": "pqiUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectsEmptyState_pqiUser"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "team",
        "args": (v3/*: any*/),
        "concreteType": "ProjectMeasuring_Team",
        "kind": "LinkedField",
        "name": "projectMeasuring_teamByPath",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "portfolioPath",
                "variableName": "portfolioPath"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ProjectsEmptyState_team"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectMeasuring_ProjectSurvey",
                "kind": "LinkedField",
                "name": "projectSurveys",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PQiUser",
        "kind": "LinkedField",
        "name": "pqiUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PQiUserGrant",
            "kind": "LinkedField",
            "name": "grants",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "portfolioId",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "team",
        "args": (v3/*: any*/),
        "concreteType": "ProjectMeasuring_Team",
        "kind": "LinkedField",
        "name": "projectMeasuring_teamByPath",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectMeasuring_ProjectSurvey",
                "kind": "LinkedField",
                "name": "projectSurveys",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08841611d41ab4d0bce3ec99f8c17100",
    "id": null,
    "metadata": {},
    "name": "ProjectsPageQuery",
    "operationKind": "query",
    "text": "query ProjectsPageQuery(\n  $teamPath: String!\n  $portfolioPath: String!\n  $onlyMyProjects: Boolean\n) {\n  pqiUser {\n    ...ProjectsEmptyState_pqiUser\n    id\n  }\n  team: projectMeasuring_teamByPath(path: $teamPath) {\n    ...ProjectsEmptyState_team_2X20fV\n    portfolio(path: $portfolioPath) {\n      __typename\n      projectSurveys {\n        projectId\n        surveyNumber\n        status\n        surveyDate\n        endDate\n        closedAt\n        id\n      }\n      projects(onlyMyProjects: $onlyMyProjects) {\n        id\n        path\n        name\n        projectManager {\n          id\n          fullName\n          emailAddress\n        }\n        status\n        surveyCount\n        improvementBankId\n        createdAt\n      }\n      id\n    }\n    projectCategories {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectsEmptyState_pqiUser on PQiUser {\n  grants {\n    role\n    teamId\n    portfolioId\n    projectId\n  }\n}\n\nfragment ProjectsEmptyState_team_2X20fV on ProjectMeasuring_Team {\n  id\n  portfolio(path: $portfolioPath) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0f3b3a1c52136a792b4e314f3fa7e9a";

export default node;
