import { useFragment, graphql } from 'react-relay';
import ArticleLinkRecord from './ArticleLinkRecord';
import ImageRecord from './image-record';
import PreambleRecord from './PreambleRecord';
import TextRecord from './TextRecord';
import HeadingRecord from './HeadingRecord';
import type { ArticleContentOld_content$key } from './__generated__/ArticleContentOld_content.graphql';

function ArticleContentOld(props: { content: ArticleContentOld_content$key }) {
    const content = useFragment(fragments.content, props.content);
    switch (content.__typename) {
        case 'ArticleLinkRecord':
            return <ArticleLinkRecord content={content} />;
        case 'HeadingRecord':
            return <HeadingRecord content={content} />;
        case 'ImageRecord':
            return <ImageRecord content={content} />;
        case 'PreambleRecord':
            return <PreambleRecord content={content} />;
        case 'TextRecord':
            return <TextRecord content={content} />;
        default:
            return null;
    }
}

const fragments = {
    content: graphql`
        fragment ArticleContentOld_content on HelpCenterArticleModelContentField {
            __typename
            ...TextRecord_content
            ...HeadingRecord_content
            ...ImageRecord_content
            ...PreambleRecord_content
            ...ArticleLinkRecord_content
        }
    `,
};

export default ArticleContentOld;
