import { graphql, loadQuery } from 'react-relay';
import type { Environment } from 'react-relay';
import type { ProjectImprovementsPageQuery$variables } from './__generated__/ProjectImprovementsPageQuery.graphql';

export const loadProjectImprovementsPageQuery = (
    environment: Environment,
    variables: ProjectImprovementsPageQuery$variables,
) => {
    return loadQuery(environment, projectImprovementsPageQuery, variables);
};

export const projectImprovementsPageQuery = graphql`
    query ProjectImprovementsPageQuery(
        $projectPath: String!
        $teamPath: String!
    ) {
        team: projectMeasuring_teamByPath(path: $teamPath) {
            project: projectByPath(projectPath: $projectPath) {
                ...ProjectImprovementsPage_project
                ...AddAreaOfImprovementPage_project
            }
            masterQuestionnaire {
                ...AreaOfImprovementTagSelect_questionnaire
                id
                questionnaireId: originalId
                indicators {
                    key
                    name
                    questionIds: originalQuestionIds
                }
                questions {
                    id
                    questionId: originalId
                    fullForm
                    shortForm
                    isMeta
                    isSurveyMeta
                }
            }
        }
    }
`;
