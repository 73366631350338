// @ts-strict-ignore
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import env from '@/env';

if (import.meta.env.DEV && !env.DATOCMS_TOKEN) {
    throw new Error(
        'Authorization token for accessing Help center cms is undefined or empty.',
    );
}
if (import.meta.env.DEV && !env.DATOCMS_URL) {
    throw new Error('Help center cms endpoint is undefined or empty.');
}

const network = Network.create(async (operation, variables) => {
    try {
        const response = await fetch(env.DATOCMS_URL, {
            // fetchTimeout: 20000, // Timeout after 20s.
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: env.DATOCMS_TOKEN,
            },
            body: JSON.stringify({
                query: operation.text,
                variables,
            }),
        });

        if (response.status === 401) {
            throw new Error(
                'We´re sorry. You are not authorized to access the given endpoint.',
            );
        }

        return await response.json();
    } catch (error) {
        console.error('Network unavailable: ' + error.message);
        throw new Error('Network unavailable at this time.');
    }
});

const recordSource = new RecordSource();
const store = new Store(recordSource, {
    // set the cache expiration time to 5 minutes.
    queryCacheExpirationTime: 5 * 60 * 1000,
});

export const HelpCenterRelayEnvironment = new Environment({
    network,
    store,
});

(window as any).binosightHelpCenterRelayRecordSource = recordSource;
