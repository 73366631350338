import { Navigate, Outlet, Route } from 'react-router-dom';
import { Suspense } from 'react';
import { getRelayEnvironment } from '@/RelayEnvironment';
import { getLastPortfolioPath } from '@/RootPaths.ts';
import { loadProjectSurveysPageQuery } from '../ProjectSurveysPage/ProjectSurveysPageQuery.ts';
import ProjectSurveysPageSkeleton from '../ProjectSurveysPage/ProjectSurveysPageSkeleton.tsx';
import { default as improveRoutes } from '../ProjectImprovements/routes.tsx';
import { loadProjectPageQuery } from './ProjectPageQuery.ts';
import { ProjectSubpageNotFound } from './ProjectSubpageNotFound.tsx';

export default (
    <>
        <Route
            lazy={() =>
                import('./ProjectPage.tsx').then((m) => ({
                    Component: m.ProjectPage,
                }))
            }
            loader={({ params }) => {
                return {
                    queryRef: loadProjectPageQuery(getRelayEnvironment(), {
                        teamPath: params.teamPath,
                        projectPath: params.projectPath,
                        portfolioPath: getLastPortfolioPath(params),
                    }),
                };
            }}
        >
            <Route
                path={`info/*`}
                lazy={() =>
                    import('./ProjectInfoPage.tsx').then((m) => ({
                        Component: m.ProjectInfoPage,
                    }))
                }
            />
            <Route path={`improve/*`}>{improveRoutes}</Route>
            <Route
                path={`custom-questions/*`}
                lazy={() =>
                    import('./ProjectCustomQuestionsPage.tsx').then((m) => ({
                        Component: m.ProjectCustomQuestionsPage,
                    }))
                }
            />
            <Route
                path={`team-members`}
                lazy={() =>
                    import('@/pages/ProjectTeamMembers/index.tsx').then(
                        (m) => ({
                            Component: m.ProjectTeamMembersPage,
                        }),
                    )
                }
            />
            <Route
                path={`delete`}
                lazy={() =>
                    import('./DeleteProjectPage.tsx').then((m) => ({
                        Component: m.DeleteProjectPage,
                    }))
                }
            />
            <Route index element={<Navigate to="surveys" replace />} />
            <Route
                element={
                    <Suspense fallback={<ProjectSurveysPageSkeleton />}>
                        <Outlet />
                    </Suspense>
                }
            >
                <Route
                    path="surveys/*"
                    lazy={() =>
                        import('@/pages/ProjectSurveysPage').then((m) => ({
                            Component: m.ProjectSurveysPage,
                        }))
                    }
                    loader={({ params }) => {
                        return {
                            queryRef: loadProjectSurveysPageQuery(
                                getRelayEnvironment(),
                                {
                                    teamPath: params.teamPath,
                                    projectPath: params.projectPath,
                                    portfolioPath: getLastPortfolioPath(params),
                                    //locale: getLocale(),
                                },
                            ),
                        };
                    }}
                />
            </Route>
            <Route path="*" Component={ProjectSubpageNotFound} />
        </Route>
    </>
);
