import { Grid, Typography, Skeleton, styled } from '@mui/material';
import { tss } from 'tss-react/mui';
import { getThemeColor } from '../../muiTheme.ts';
import { sidebarWidth } from './constants.ts';

export function SidebarSkeleton({
    isMobile = false,
    itemCount = 4,
    hasBackLink = false,
}: {
    isMobile?: boolean;
    itemCount?: number;
    hasBackLink?: boolean;
}) {
    const { classes } = usePageSidebarSkeletonStyles();
    if (isMobile) {
        return (
            <div className={classes.mobileSkeletonContainer}>
                {Array.from({ length: 4 })
                    .fill('')
                    .map((_, idx) => (
                        <Typography
                            key={idx}
                            component="div"
                            variant="subtitle1"
                            className={classes.mobileSkeletonContainerItem}
                        >
                            <BlockSkeleton />
                        </Typography>
                    ))}
            </div>
        );
    }

    const items = Array(itemCount).fill('');
    return (
        <div>
            {hasBackLink && <BlockSkeleton />}
            {items.map((_, idx) => (
                <GridSkeleton
                    key={idx}
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Skeleton variant="circular" width={30} height={30} />
                    </Grid>
                    <Grid item>
                        <Skeleton width={sidebarWidth - 100} />
                    </Grid>
                </GridSkeleton>
            ))}
        </div>
    );
}

const GridSkeleton = styled(Grid)(({ theme }) => ({
    borderBottom: `solid 1px ${getThemeColor(theme)('lightGray')}`,
    padding: '28px 24px',
    boxSizing: 'border-box',
}));

const BlockSkeleton = styled(Skeleton)(() => ({
    display: 'block',
}));

const usePageSidebarSkeletonStyles = tss.create(({ theme }) => ({
    mobileSkeletonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 12px 8px 20px',
    },
    mobileSkeletonContainerItem: {
        width: 75,
        [theme.breakpoints.up('sm')]: {
            width: 100,
        },
    },
}));
