import { Navigate, Route } from 'react-router-dom';

export default (
    <>
        <Route
            path={`info/*`}
            lazy={() =>
                import('@/pages/OldProjectInfo').then((m) => ({
                    Component: m.OldProjectInfoPage,
                }))
            }
        />
        <Route
            path={`surveys/*`}
            lazy={() =>
                import('@/pages/OldProjectSurveys').then((m) => ({
                    Component: m.default,
                }))
            }
        />
        <Route path={'*'} element={<Navigate to={`surveys`} />} />
    </>
);
