import { useFragment, graphql } from 'react-relay';
import { styled } from '@mui/material';
import type { TextRecord_content$key } from './__generated__/TextRecord_content.graphql';

export const StyledText = styled('div')(({ theme }) => ({
    '& > p': {
        fontSize: 18,
        margin: '24px 0',
        lineHeight: '24px',
    },
    '& > a': {
        fontSize: 18,
        lineHeight: '24px',
        margin: 0,
        display: 'block',
    },
    '& p': {
        color: theme.palette.text.primary,
    },
    '& ul': {
        margin: '16px 0 0 0',

        '& li': {
            marginBottom: 8,

            '&:last-child': {
                marginBottom: 0,
            },

            [theme.breakpoints.up('md')]: {
                marginBottom: 13,
            },
        },

        [theme.breakpoints.up('md')]: {
            margin: '20px 0 0 0',
        },
    },
    '& blockquote': {
        borderLeft: '.2em solid rgba(0,0,0,.2)',
        margin: '0 0 0 .2em',
        opacity: '.6',
        // paddingLeft: '.8em',
        background: theme.bino.color.backGroundGray,
        padding: '1rem',
        '& > p:first-child': {
            marginTop: 0,
        },
        '& p': {
            fontSize: 16,
            lineHeight: '26px',
            color: theme.palette.text.primary,
            margin: 'calc(16px / 2) 0 0 0',

            [theme.breakpoints.up('sm')]: {
                margin: 'calc(24px / 2) 0 0 0',
            },
        },
    },
}));

export const TextRecord = (props: { content: TextRecord_content$key }) => {
    const content = useFragment(fragments.content, props.content);
    return (
        <StyledText
            as="section"
            dangerouslySetInnerHTML={{ __html: content.text }}
        />
    );
};

const fragments = {
    content: graphql`
        fragment TextRecord_content on TextRecord {
            id
            text
        }
    `,
};

export default TextRecord;
