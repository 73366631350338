/**
 * @generated SignedSource<<6b4a72f80bb8329a4b7ffd36a7310f64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageRecord_content$data = {
  readonly caption: string | null | undefined;
  readonly id: string;
  readonly image: {
    readonly alt: string | null | undefined;
    readonly id: string;
    readonly lgImg: {
      readonly base64: string | null | undefined;
      readonly height: number;
      readonly src: string;
      readonly width: number;
    } | null | undefined;
    readonly mdImg: {
      readonly base64: string | null | undefined;
      readonly height: number;
      readonly src: string;
      readonly width: number;
    } | null | undefined;
    readonly smImg: {
      readonly base64: string | null | undefined;
      readonly height: number;
      readonly src: string;
      readonly width: number;
    } | null | undefined;
    readonly title: string | null | undefined;
    readonly url: string;
  } | null | undefined;
  readonly " $fragmentType": "ImageRecord_content";
};
export type ImageRecord_content$key = {
  readonly " $data"?: ImageRecord_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageRecord_content">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "src",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "base64",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "height",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "width",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__ImageRecord_lgImageSize_relayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__ImageRecord_mdImageSize_relayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__ImageRecord_smImageSize_relayprovider"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageRecord_content",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FileField",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alt",
          "storageKey": null
        },
        {
          "alias": "lgImg",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "w",
                  "variableName": "__relay_internal__pv__ImageRecord_lgImageSize_relayprovider"
                }
              ],
              "kind": "ObjectValue",
              "name": "imgixParams"
            }
          ],
          "concreteType": "ResponsiveImage",
          "kind": "LinkedField",
          "name": "responsiveImage",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": "mdImg",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "w",
                  "variableName": "__relay_internal__pv__ImageRecord_mdImageSize_relayprovider"
                }
              ],
              "kind": "ObjectValue",
              "name": "imgixParams"
            }
          ],
          "concreteType": "ResponsiveImage",
          "kind": "LinkedField",
          "name": "responsiveImage",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": "smImg",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "w",
                  "variableName": "__relay_internal__pv__ImageRecord_smImageSize_relayprovider"
                }
              ],
              "kind": "ObjectValue",
              "name": "imgixParams"
            }
          ],
          "concreteType": "ResponsiveImage",
          "kind": "LinkedField",
          "name": "responsiveImage",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ImageRecord",
  "abstractKey": null
};
})();

(node as any).hash = "357ed83ae4e0f896b84eea8b3b0c6562";

export default node;
